import React from "react";
import styled from "styled-components";
import CardAdImages from "./CardAdImages";

// ✅ Container principale con gestione della modalità orizzontale
const CardAd = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => (props.horizontal ? "100%" : "320px")};
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  justify-content: flex-start;
  align-items: center;
  gap: ${(props) => (props.horizontal ? "10px" : "14px")};
  padding: ${(props) => (props.horizontal ? "10px 15px" : "0")};
  border-radius: 12px;
  border: ${(props) => (props.horizontal ? "1px solid #ddd" : "none")};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${(props) => (props.horizontal ? "#f8f8f8" : "transparent")};
  }
`;

const CardAdWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: ${(props) => (props.horizontal ? "100%" : "auto")};
`;

// ✅ Nuovo container per l'icona, usato solo nella versione orizzontale
const IconContainer = styled.div`
  width: 48px;
  height: 50px;
  border-radius: 8px;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const CardAdImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

const CardAdImage = styled.div`
  width: 310px;
  height: 293px;
  border-radius: 12px;
  overflow: hidden;
`;

const ADVChip = styled.div`
  display: flex;
  width: 157px;
  height: 30px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: #e0e0e0;
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 14px;
  font-weight: bold;
  z-index: 2;
`;

const CardAdTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
`;

// per versione orizzontale

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 600;
  color: #1d1d1b;
`;

const Badge = styled.div`
  position: absolute;
  top: 8px; /* ✅ Posizionato sopra */
  right: 8px; /* ✅ Posizionato a destra */
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  color: black;
  background: ${(props) =>
    props.type === "ORO" ? "#F4C542" : 
    props.type === "ARGENTO" ? "#B0B0B0" : "transparent"};
  z-index: 3; /* ✅ Assicura che sia sopra gli altri elementi */
`;



//fine solo per componente orizzontale

const TitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #1d1d1b;
`;

const SellerText = styled.div`
  font-size: 12px;
  color: #6a6a6a;
`;

const StateText = styled.div`
  font-size: 12px;
  color: #6a6a6a;
`;

const PriceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #1d1d1b;
`;




// ✅ Versione aggiornata senza `CardAdImages` in modalità orizzontale
const CardProduct = ({ id, images, title, category, seller, state, price, sponsored, premiumPackage, onClick, horizontal = false, icon = null }) => {
      console.log("🏷 Badge Debug:", {
        premiumPackage,
        packageName: premiumPackage?.nome,
        shouldShowBadge: premiumPackage?.nome === "ORO" || premiumPackage?.nome === "ARGENTO"
      });
    return (
        <CardAd onClick={onClick} horizontal={horizontal}>
            {/* ✅ Mostra il badge SOLO se ha un pacchetto associato */}
            {premiumPackage && (
              <Badge type={premiumPackage?.nome}>
                  {premiumPackage?.nome === "ORO" ? "O" : premiumPackage?.nome === "ARGENTO" ? "A" : ""}
              </Badge>
            )}

            {/* Icona a sinistra in modalità orizzontale */}
            {horizontal && (
                <IconContainer>
                    {icon}
                </IconContainer>
            )}

            {/* Modalità VERTICALE: Mostra immagine */}
            {!horizontal && (
                <CardAdImageWrapper>
                    {sponsored && <ADVChip>Sponsorizzato</ADVChip>}
                    <CardAdImage>
                        <CardAdImages images={images} />
                    </CardAdImage>
                </CardAdImageWrapper>
            )}

            {/* Wrapper per i testi */}
            <CardAdWrapper horizontal={horizontal}>
                {horizontal ? (
                    <TextContainer>
                        <span>{category || "Senza categoria"}</span>
                        <span>|</span>
                        <span>{title || "Nessun titolo"}</span>
                        <span>|</span>
                        <span>{price ? `${price} €` : "Prezzo non disponibile"}</span>
                    </TextContainer>
                ) : (
                    <>
                        <CardAdTextContainer>
                            <TitleText>{title || "Nessun titolo"}</TitleText>
                        </CardAdTextContainer>
                        <CardAdTextContainer>
                            <SellerText>{seller}</SellerText>
                        </CardAdTextContainer>
                        <CardAdTextContainer>
                            <StateText>{state}</StateText>
                        </CardAdTextContainer>
                        <CardAdTextContainer>
                            <PriceText>{price} €</PriceText>
                        </CardAdTextContainer>
                    </>
                )}
            </CardAdWrapper>
        </CardAd>
    );
};


export default CardProduct;
