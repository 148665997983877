import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { useUser } from "../context/UserContext"; // 🔹 Importiamo il contesto utente
import MenuIcon from "../assets/icons/burger.svg"; // Icona burger
import UserIcon from "../assets/icons/user.svg"; // Icona utente
import UserMenuDropdown from "./UserMenuDropdown"; // Import del dropdown
import LoginForm from "./LoginForm"; // Import del LoginForm
import RegisterForm from "./RegisterForm"; // Import del RegisterForm
import { useAnnuncio } from "../context/AnnuncioContext";
import { useNavigate, useLocation } from "react-router-dom";
import LogoAnimated from "./LogoAnimated";


// Styled Components
const NavbarTop = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: ${(props) => (props.isMessaggiMode ? 'fixed' : 'relative')};
  top:0;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  .container {
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const LogoAnimationWrapper = styled.div`
  width: 80px;
`;


const RightMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative; /* Necessario per il posizionamento del dropdown */

  .user-dropdown-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px; /* Spazio tra 'Ciao!' e l'icona */
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Sfondo semi-trasparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200; /* Assicura che sia sopra altri elementi */
`;


const UserMenuDropdownWrapper = styled.div`
  position: absolute;
  top: 60px; /* Posiziona il dropdown sotto l'icona */
  right: 0; /* Allineamento a destra */
  z-index: 100; /* Assicura che sia visibile sopra altri elementi */
`;

// Componente NavbarTop
const NavbarTopComponent = ({ isMessaggiMode, setIsMessaggiMode }) => {
    const { user, handleLoginSuccess, handleLogout } = useUser(); // 🔹 Recuperiamo i dati utente dal contesto
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
    const { startCreatingAnnuncio } = useAnnuncio();
    const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleMessaggiClick = () => {
    setIsMessaggiMode(true);  // 🔹 Usa la prop passata
    };


    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const openLoginForm = () => {
        setIsLoginFormOpen(true);
        setIsRegisterFormOpen(false);
        closeDropdown();
    };

    const openRegisterForm = () => {
        setIsRegisterFormOpen(true);
        setIsLoginFormOpen(false);
        closeDropdown();
    };

    const closeForms = () => {
        setIsLoginFormOpen(false);
        setIsRegisterFormOpen(false);
    };

    return (
        <>
            <NavbarTop isMessaggiMode={isMessaggiMode}>
                {/* Logo */}
                <Logo>
                <div className="container">
                    <LogoAnimationWrapper>
                    <LogoAnimated />
                    </LogoAnimationWrapper>
                </div>
                </Logo>

                {/* Menu utente */}
                <RightMenu>
                    <Typography
                    className="section-item"
                    variant="body1"
                    onClick={() => {
                        if (user.isLoggedIn) {
                        // Esegui la logica per iniziare la creazione annuncio
                        startCreatingAnnuncio(); // Se ce l'hai disponibile qui
                        navigate("/crea-annuncio"); // o la tua route reale
                        } else {
                        openLoginForm(); // Se non loggato, mostra il login
                        }
                    }}
                    >
                    Vendi su Perstudenti
                    </Typography>


                    {/* Icona burger */}
                    <div className="user-dropdown-trigger" onClick={toggleDropdown}>
                        <img src={MenuIcon} alt="Menu" />
                    </div>

                    {/* Icona user con "Ciao!" se l'utente è loggato */}
                    <div className="user-dropdown-trigger" onClick={toggleDropdown}>
                        {user.isLoggedIn && <Typography variant="body1">Ciao!</Typography>}
                        <img src={UserIcon} alt="User" />
                    </div>

                    {/* Dropdown */}
                    {isDropdownOpen && (
                        <UserMenuDropdownWrapper>
                            <UserMenuDropdown
                            user={user}
                            onClose={closeDropdown}
                            onOpenLoginForm={openLoginForm}
                            onOpenRegisterForm={openRegisterForm}
                            handleLogout={handleLogout} // ✅ Passiamo la funzione di logout
                            handleMessaggiClick={() => setIsMessaggiMode(true)} 
                            />
                        </UserMenuDropdownWrapper>
                    )}
                </RightMenu>
            </NavbarTop>

            {/* Modale del LoginForm */}
            {isLoginFormOpen && (
                <ModalOverlay>
                <LoginForm onClose={closeForms} handleLoginSuccess={handleLoginSuccess} />
                </ModalOverlay>
            )}

            {/* Modale del RegisterForm */}
            {isRegisterFormOpen && (
                <ModalOverlay>
                <RegisterForm onClose={closeForms} handleLoginSuccess={handleLoginSuccess} />
                </ModalOverlay>
            )}
        </>
    );
};

export default NavbarTopComponent;
