import React, { useEffect, useRef } from 'react';
import { TextField, Typography, Box, IconButton, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';
import RoundButton from '../RoundButton';  // 🔄 Importa il componente RoundButton

const ChatContainer = styled.div`
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ContactBadge = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 8px;
    gap: 10px;
    justify-content: space-between;  // 🔄 Distribuisce spazio tra elementi
`;

const ContactInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ContactName = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: #333;
`;

const MessagesContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 8px;
    padding-bottom: 10px;
`;

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.sent ? 'row-reverse' : 'row')};  
    align-items: flex-end;
    margin-bottom: 8px;
    gap: 10px;  
`;

const MessageBubble = styled.div`
    max-width: 75%;                          
    min-width: 120px;                         
    padding: 8px 12px;
    border-radius: 8px;
    background-color: ${(props) => (props.sent ? '#d1f5d3' : '#f0f0f0')};  
    color: #000;
    margin-bottom: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: inline-block;
    word-break: break-word;                  
    white-space: pre-wrap;                   
    text-align: left;                        
`;

const AvatarWrapper = styled.div`
    margin: 0 8px;
    display: ${(props) => (props.sent ? 'none' : 'block')};  
`;

const Timestamp = styled(Typography)`
    font-size: 12px;
    color: #777;
    margin-top: 2px;
    text-align: ${(props) => (props.sent ? 'right' : 'left')};  
    margin-left: ${(props) => (props.sent ? 'auto' : '0')};     
    margin-right: ${(props) => (props.sent ? '0' : 'auto')};    
`;

const DateDivider = styled.div`
    text-align: center;
    color: #777;
    font-size: 12px;
    margin: 10px 0;
    position: relative;
    &:before,
    &:after {
        content: '';
        display: inline-block;
        width: 40%;
        border-top: 1px solid #ddd;
        margin: 0 10px;
    }
`;

const InputContainer = styled(Box)`
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding: 8px;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    z-index: 10;
`;

const ChatView = ({ selectedMessage, user, newMessage, setNewMessage, handleSendMessage, isAdDetailOpen, onShowAdDetails }) => {
    const messagesEndRef = useRef(null);
    let lastDate = null;  // 🔹 Variabile per tracciare la data dell'ultimo messaggio

    // Scroll automatico verso il basso quando arrivano nuovi messaggi
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedMessage?.messaggi]);
    return (
        <ChatContainer>

            {/* 🔄 ContactBadge: Mostra avatar, nome e bottone "Mostra annuncio" */}
            {selectedMessage && (
                <ContactBadge>
                    <ContactInfo>
                        <Avatar
                            src={selectedMessage.venditore?.avatar || "https://via.placeholder.com/40"}
                            alt="Avatar"
                        />
                        <ContactName variant="h6">
                            {selectedMessage.venditore?.nome || "Venditore sconosciuto"}
                        </ContactName>
                    </ContactInfo>

                    {/* 🔄 Pulsante "Mostra annuncio" */}
                    {!isAdDetailOpen && (
                        <RoundButton onClick={onShowAdDetails}>
                            Mostra annuncio
                        </RoundButton>
                    )}
                </ContactBadge>
            )}


            <MessagesContainer>
                {selectedMessage ? (
                    selectedMessage.messaggi.map((msg, index) => {
                        const messageDate = new Date(msg.data_invio).toLocaleDateString('it-IT', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        });

                        const messageTime = new Date(msg.data_invio).toLocaleTimeString('it-IT', {
                            hour: '2-digit',
                            minute: '2-digit'
                        });

                        const showDateDivider = messageDate !== lastDate;
                        lastDate = messageDate;

                        return (
                            <React.Fragment key={index}>
                                {showDateDivider && (
                                    <DateDivider>{messageDate}</DateDivider>  // 🔹 Mostra la data se cambia
                                )}
                                <MessageWrapper sent={msg.mittente === user.userId}>
                                    <AvatarWrapper sent={msg.mittente === user.userId}>
                                        <Avatar
                                            src={selectedMessage.venditore?.avatar || "https://via.placeholder.com/40"}
                                            alt="Avatar"
                                        />
                                    </AvatarWrapper>
                                    <div>
                                        <MessageBubble sent={msg.mittente === user.userId}>
                                            <Typography variant="body2">{msg.testo}</Typography>
                                        </MessageBubble>
                                        <Timestamp sent={msg.mittente === user.userId}>
                                            {messageTime} 
                                        </Timestamp>
                                    </div>
                                </MessageWrapper>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <Typography variant="body2" style={{ textAlign: 'center', marginTop: '16px', color: '#888' }}>
                        Seleziona una conversazione per visualizzare i messaggi
                    </Typography>
                )}
                <div ref={messagesEndRef} />
            </MessagesContainer>

            <InputContainer>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Scrivi un messaggio"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSendMessage();
                    }}
                    sx={{ mr: 2 }}
                />
                <IconButton color="primary" onClick={handleSendMessage}>
                    <SendIcon />
                </IconButton>
            </InputContainer>
        </ChatContainer>
    );
};

export default ChatView;
