import React from "react";
import styled from "styled-components";
import ConditionIcon from "../../../assets/icons/condition.svg";
import ShippingIcon from "../../../assets/icons/shipping.svg";
import ReturnIcon from "../../../assets/icons/return.svg";

// Styled Components
const AdMobileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.background.paper};
  width: 100%;
  max-width: 360px; /* Ottimizzato per schermi piccoli */
`;

const Title = styled.h2`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 8px;
`;

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    color: ${(props) => props.theme.palette.text.secondary};
    font-family: "PP Gosha Sans";
    font-size: 12px;
    font-weight: 400;
  }

  .value {
    color: ${(props) => props.theme.palette.text.primary};
    font-family: "PP Gosha Sans";
    font-size: 14px;
    font-weight: 700;
  }
`;

// Component
const AdMobileDetails = ({ condizioni = "Non specificato", spedizione, reso }) => {
  const details = [
    {
      icon: ConditionIcon,
      label: "Stato del prodotto",
      value: condizioni,
    },
    {
      icon: ShippingIcon,
      label: "Spedizione digitale",
      value: spedizione === true ? "Sì" : "No",  // 👈 Conversione sicura
    },
    {
      icon: ReturnIcon,
      label: "Reso dell'oggetto",
      value: reso === true ? "Sì" : "No",  // 👈 Conversione sicura
    },
  ];


  return (
    <AdMobileDetailsWrapper>
      <Title>Dettagli annuncio</Title>
      <DetailsContainer>
        {details.map((detail, index) => (
          <DetailItem key={index}>
            <IconWrapper>
              <img src={detail.icon} alt={detail.label} />
            </IconWrapper>
            <DetailText>
              <div className="label">{detail.label}</div>
              <div className="value">{detail.value}</div>
            </DetailText>
          </DetailItem>
        ))}
      </DetailsContainer>
    </AdMobileDetailsWrapper>
  );
};

export default AdMobileDetails;
