import axios from "axios";
import { useUser } from "../context/UserContext";

// ✅ Configurazione URL di base
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api";

// ✅ Funzione aggiornata per ottenere cookie (per CSRF)
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};





// ✅ Crea un'istanza Axios di base
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,  // ✅ Necessario per cookie HttpOnly
});

// ✅ Interceptor per impostare CSRF token
axiosInstance.interceptors.request.use(
  (config) => {
    const csrftoken = getCookie('csrftoken');
    if (csrftoken) {
      config.headers['X-CSRFToken'] = csrftoken;
    }
    console.log("📢 Config richiesta:", config);  // 🔄 Log dettagliato
    return config;
  },
  (error) => Promise.reject(error)
);

// ✅ Interceptor per il refresh del token di accesso


axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("❌ Errore nella chiamata API:", error.response);
    const originalRequest = error.config;

    // 🔄 1️⃣ Controlla se il refresh token esiste prima di tentare il refresh
    const refreshToken = document.cookie.split('; ').find(row => row.startsWith('refresh_token='));

    if (!refreshToken) {
      console.log("❌ Nessun `refresh_token` nei cookie. Nessun tentativo di refresh.");
      return Promise.reject(error);
    }

    // 🔄 2️⃣ Controlla se è un errore 401 (non autorizzato)
    if (error.response && error.response.status === 401) {

      // 🔄 3️⃣ Se il refresh token ha fallito, esegui il logout
      if (originalRequest.url.includes("/refresh_token/")) {
        console.log("❌ Refresh token fallito. Logout in corso...");
        await logoutUser();  // 🔄 Esegui logout
        return Promise.reject(error); 
      }

      // 🔄 4️⃣ Se il token è scaduto, prova a rinnovarlo una sola volta
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        console.log("🔄 Token scaduto. Tentativo di refresh...");

        try {
          const refreshResponse = await axiosInstance.post("/refresh_token/", null, {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': getCookie('csrftoken'),
            },
            withCredentials: true  
          });

          console.log("✅ Nuovo access token ottenuto tramite cookie.");
          return axiosInstance(originalRequest);  // 🔄 Riprova la richiesta con il nuovo token
        } catch (refreshError) {
          console.error("❌ Errore nel refresh del token:", refreshError);
          await logoutUser();  // 🔥 Logout se il refresh fallisce
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);




// ✅ Funzione per eseguire il logout forzato in caso di errore
const logoutUser = () => {
  console.log("🚪 Logout forzato: Token non valido o scaduto.");
  axiosInstance.post("/logout/")
    .then(() => {
      alert("La tua sessione è scaduta. Fai di nuovo il login.");
      window.location.href = "/login";
    })
    .catch((error) => {
      console.error("❌ Errore durante il logout:", error);
    });
};


export { getCookie, logoutUser };
export default axiosInstance;
