import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useUser } from "./context/UserContext";
import { useAnnuncio } from "./context/AnnuncioContext";
import { ThemeProvider } from "@mui/material/styles";
import "./assets/styles/App.css";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./Theme";

import Navbar from "./components/Navbar";


import ElencoAnnunci from "./components/ElencoAnnunci";
import ConfirmUserPage from "./pages/ConfirmUserPage";
import AdPage from "./components/Ad/AdPage";
import GestisciAnnunci from "./components/GestisciAnnunci";
import Messaggi from "./components/Message/Messaggi";
import DialogExitConfirmation from "./components/DialogExitConfirmation";
import AreaRiservataMobile from "./components/Mobile/AreaRiservataMobile";
import ModalRoutes from "./components/ModalRoutes";

// 🔹 Funzione per importare i componenti dinamicamente
const loadStepComponent = (stepName) => {
  try {
    return lazy(() => import(`./components/CreaAd/${stepName}`));
  } catch (error) {
    console.error(`❌ Errore nel caricamento del componente ${stepName}:`, error);
    return null;
  }
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const backgroundLocation = state?.backgroundLocation;



  const { user, loading, handleLogout } = useUser();  // 🔄 Usa loading dal UserContext
  const { annuncioTipo, annuncioStep, steps } = useAnnuncio();

  const [isMessaggiMode, setIsMessaggiMode] = useState(false);




    useEffect(() => {
      // 🔹 Gestione della modalità Messaggi e visibilità della NavbarBottom
      if (location.pathname === "/messaggi") {
        setIsMessaggiMode(true);
      } else {
        setIsMessaggiMode(false);
      }
    }, [location]);

  const handleLogoutClick = async () => {
    try {
      await handleLogout();
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // 🔹 Verifichiamo se siamo in modalità annuncio e se lo step è valido
  const isAnnuncioAttivo = annuncioTipo !== null && steps[annuncioStep] !== undefined;
  const StepComponent = isAnnuncioAttivo ? loadStepComponent(steps[annuncioStep]) : null;

  if (loading) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <DialogExitConfirmation /> {/* Modale globale */}

        {/* 🔹 Mostriamo il flusso di creazione/modifica solo se attivo */}
        {isAnnuncioAttivo ? (
          <Suspense fallback={<div>Caricamento...</div>}>
            {StepComponent && <StepComponent />}
          </Suspense>
        ) : (
          <>
          
            <Navbar
              isUserLoggedIn={user.isLoggedIn}
              username={user.username}
              avatar={user.avatar}
              handleLogout={handleLogoutClick}
              isMessaggiMode={isMessaggiMode}
              setIsMessaggiMode={setIsMessaggiMode} 
                style={{
                position: isMessaggiMode ? 'fixed' : 'static',
                top: 0,
                width: '100%',
                zIndex: 1000  // 🔹 Assicura che sia sopra tutti gli altri elementi
              }}
            />

            {/* 🔹 Rotte principali */}
            <Routes location={backgroundLocation || location}>
              <Route path="/" element={<ElencoAnnunci />} />              
              <Route path="/area-riservata" element={<AreaRiservataMobile />} />
              
              <Route path="/gestisci-annunci" element={<GestisciAnnunci />} />
              <Route path="/messaggi" element={<Messaggi isMessaggiMode={isMessaggiMode}  />} />
              <Route path="/annuncio/:annuncio_id" element={<AdPage />} />
              <Route path="/confirm_user/:uid/:token" element={<ConfirmUserPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            {(location.pathname === "/login" || location.pathname === "/registrati") && backgroundLocation && (
              <div className="modal-wrapper">
                <ModalRoutes />
              </div>
            )}
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
