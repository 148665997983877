import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import ChatView from './ChatView';
import AdDetailChat from './AdDetailChat';
import styled from 'styled-components';
import { useMessaggi } from '../../context/MessaggiContext';  // 🔹 Importa il context dei messaggi
import { useUser } from '../../context/UserContext';  // 🔹 Importa il context utente
import { useAnnuncio } from '../../context/AnnuncioContext';
import { useMediaQuery } from '@mui/material';
import MessaggiMobile from './Mobile/MessaggiMobile';  // 🔄 Importa la versione mobile


const Container = styled.div`
    display: flex;
    background-color: #f5f5f5;
    height: 100vh;
    padding-top: 56px;
    overflow-y: hidden;
`;

const Column = styled.div`
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;
`;

const SidebarColumn = styled(Column)`
    width: 25%;
    border-right: 1px solid #e0e0e0;
`;

const ChatColumn = styled(Column)`
    width: ${(props) => (props.isAdDetailOpen ? "50%" : "75%")};  // 🔄 Si espande se AdDetail è chiuso
    border-right: ${(props) => (props.isAdDetailOpen ? "1px solid #e0e0e0" : "none")};  // 🔄 Rimuove la linea quando espanso
    transition: width 0.3s ease-in-out;  // 🔄 Transizione fluida
`;

const AdDetailColumn = styled(Column)`
    width: ${(props) => (props.isAdDetailOpen ? "25%" : "0")};  // 🔄 Nasconde la colonna se chiusa
    overflow: hidden;  // 🔄 Nasconde contenuto se la larghezza è 0
    transition: width 0.3s ease-in-out;  // 🔄 Transizione fluida
`;

const Messaggi = ({ isMessaggiMode }) => {
    const { conversazioni, fetchConversazioni, inviaMessaggio, loading, error } = useMessaggi();
    const { user } = useUser();
    const { fetchAnnuncioCorrente } = useAnnuncio();
    
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [annuncio, setAnnuncio] = useState(null);  // 🔄 Stato per l'annuncio selezionato
    const [newMessage, setNewMessage] = useState("");
    const [isAdDetailOpen, setIsAdDetailOpen] = useState(true);  // 🔹 Gestisce apertura/chiusura di AdDetail

     const lastAnnuncioId = useRef(null);

     const isMobile = useMediaQuery('(max-width: 768px)');  // 🔄 Rileva se la larghezza è max 768px


    // 🔄 Carica le conversazioni all'inizio
    useEffect(() => {
        fetchConversazioni();
    }, []);

        // 🔄 Seleziona la prima conversazione di default quando vengono caricate
useEffect(() => {
    if (conversazioni.length > 0 && !selectedMessage) {
        // 🔄 Ordina le conversazioni per data più recente prima di selezionare la prima
        const sortedConversazioni = [...conversazioni].sort((a, b) => 
            new Date(b.data_ultimo_messaggio) - new Date(a.data_ultimo_messaggio)
        );
        setSelectedMessage(sortedConversazioni[0]);  // 🔄 Seleziona la conversazione più recente
    }
}, [conversazioni, selectedMessage]);  // 🔄 Si attiva solo quando cambia la lista delle conversazioni


    // 🔄 Recupera i dettagli dell'annuncio quando cambia `selectedMessage`
    useEffect(() => {
        if (selectedMessage?.annuncio && selectedMessage.annuncio !== lastAnnuncioId.current) {
            console.log("📌 ID Annuncio selezionato:", selectedMessage.annuncio);
            fetchAnnuncioCorrente(selectedMessage.annuncio);
            lastAnnuncioId.current = selectedMessage.annuncio;  // 🔄 Aggiorna l'ID caricato
        }
    }, [selectedMessage, fetchAnnuncioCorrente]);

    const handleSelectMessage = (msg) => {
    setSelectedMessage(msg);
    setIsAdDetailOpen(true);  // 🔄 Riapre AdDetailChat se è stato chiuso
};


    // 🔄 Gestisce l'invio di nuovi messaggi
    const handleSendMessage = async () => {
        if (newMessage.trim() && selectedMessage) {
            const messaggioInviato = await inviaMessaggio(selectedMessage.id, newMessage);
            if (messaggioInviato) {
                setSelectedMessage((prev) => ({
                    ...prev,
                    messaggi: [...prev.messaggi, messaggioInviato],
                }));
            }
            setNewMessage("");
        }
    };

    if (loading) return <div>Caricamento...</div>;
    if (error) return <div>Errore: {error}</div>;

    if (isMobile) {
    return (
        <MessaggiMobile />
    );
}

    return (
        <Container>
            <SidebarColumn>
                <Sidebar
                    conversazioni={conversazioni}
                    selectedMessage={selectedMessage}
                    setSelectedMessage={handleSelectMessage}
                    setAnnuncio={setAnnuncio}  // 🔄 Passa `setAnnuncio` a Sidebar per aggiornare l'annuncio selezionato
                />
            </SidebarColumn>
            <ChatColumn isAdDetailOpen={isAdDetailOpen}>
                <ChatView
                    selectedMessage={selectedMessage}
                    user={user}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    handleSendMessage={handleSendMessage}
                    isAdDetailOpen={isAdDetailOpen}
                    onShowAdDetails={() => setIsAdDetailOpen(true)}
                />
            </ChatColumn>
            <AdDetailColumn isAdDetailOpen={isAdDetailOpen}>
                <AdDetailChat
                    selectedMessage={selectedMessage}
                    annuncio={annuncio}  // 🔄 Passa i dettagli dell'annuncio
                    onClose={() => setIsAdDetailOpen(false)}
                />
            </AdDetailColumn>
        </Container>
    );
};

export default Messaggi;
