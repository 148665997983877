import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axiosInstance from "../../api/axiosConfig";
import { useUser } from "../../context/UserContext";
import { useAnnuncio } from "../../context/AnnuncioContext";
import CardProduct from "../Ad/CardProduct";
import InserisciAnnuncioCard from "./InserisciAnnuncioCard";

// Styled Components
const Container = styled.div`
  padding: 16px;
  padding-bottom: 100px; // spazio per la NavbarMobileBottom
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const FiltersContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 10px;
  margin-bottom: 20px;
`;

const FilterChip = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid #000;
  background-color: ${(props) => (props.selected ? "#000" : "#fff")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  cursor: pointer;
  white-space: nowrap;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NoResults = styled.p`
  text-align: center;
  font-size: 14px;
  color: #777;
`;

const GestisciAnnunciMobile = () => {
  const { user } = useUser();
  const { recuperoAnnuncio, setAnnuncioStep, setTipoAnnuncio } = useAnnuncio();
  const [selectedFilter, setSelectedFilter] = useState("Pubblicati");
  const [annunci, setAnnunci] = useState([]);
  const [counts, setCounts] = useState({});

  const STATUS_MAP = {
    Pubblicati: "Approvato",
    "In approvazione": "Da approvare",
    Bozza: "Bozza",
    Rifiutato: "Rifiutato",
  };

  useEffect(() => {
    const fetchCounts = async () => {
      const results = {};
      await Promise.all(
        Object.entries(STATUS_MAP).map(async ([label, stato]) => {
          const res = await axiosInstance.get(`/i_miei_annunci/?stato=${stato}`);
          results[label] = res.data.results.length;
        })
      );
      setCounts(results);
    };

    const fetchAnnunci = async () => {
      const backendStatus = STATUS_MAP[selectedFilter];
      const response = await axiosInstance.get(`/i_miei_annunci/?stato=${backendStatus}`);
      setAnnunci(response.data.results);
    };

    if (user.isLoggedIn) {
      fetchCounts();
      fetchAnnunci();
    }
  }, [selectedFilter, user.isLoggedIn]);

  const handleAnnuncioClick = async (annuncio) => {
    const tipo = annuncio.stato === "Approvato" ? "modifica" : annuncio.stato === "Rifiutato" ? "rifiutato" : "draft";
    setTipoAnnuncio(tipo);
    localStorage.setItem("annuncioId", annuncio.id);
    await recuperoAnnuncio(annuncio.id, annuncio.stato);
    setAnnuncioStep(0);
  };

  return (
    <Container>
      <Title>I miei annunci</Title>

      <FiltersContainer>
        {Object.keys(STATUS_MAP).map((status) => (
          <FilterChip
            key={status}
            selected={selectedFilter === status}
            onClick={() => setSelectedFilter(status)}
          >
            {status} ({counts[status] || 0})
          </FilterChip>
        ))}
      </FiltersContainer>

      <ListContainer>
        {annunci.length > 0 ? (
          annunci.map((annuncio) => (
            <CardProduct
              key={annuncio.id}
              id={annuncio.id}
              title={annuncio.titolo}
              category={annuncio.categoria}
              price={annuncio.prezzo ? `${annuncio.prezzo} €` : "Prezzo non specificato"}
              horizontal={true}
              images={[annuncio.img_main_url]}
              onClick={() => handleAnnuncioClick(annuncio)}
            />
          ))
        ) : (
          <NoResults>Nessun annuncio {selectedFilter.toLowerCase()} disponibile.</NoResults>
        )}
      </ListContainer>

      <InserisciAnnuncioCard />
    </Container>
  );
};

export default GestisciAnnunciMobile;
