import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import axios from "../../../api/axiosConfig";

// Componenti importati
import AdImgPageMobile from "./AdImgPageMobile";
import AdMobileInfo from "./AdMobileInfo";
import ContactFormMobile from "./ContactFormMobile";
import AdMobileDetails from "./AdMobileDetails";
import SellerMobileBadge from "./SellerMobileBadge";
import AdMobileMap from "./AdMobileMap";
import AdMobileRelateds from "./AdMobileRelateds";


/* 🔹 Wrapper per la sezione immagine (NON ha padding) */
const AdMobileImageWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const AdMobilePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.background.default};
  align-items: flex-start; // 👈 Allinea tutto a sinistra
  width: 100%; // 👈 Assicura che occupi tutta la larghezza
  max-width: 768px; // 👈 Per non andare oltre i limiti dello schermo mobile
  margin: 0 auto; // 👈 Per centrare l'intera colonna se necessario
`;

const AdMobilePage = () => {
  const { annuncio_id } = useParams();
  const [adData, setAdData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdDetails = async () => {
      try {
        const response = await axios.get(`/annuncio/${annuncio_id}/`);
          setAdData({
            ...response.data,
            venditore: {
              ...response.data.venditore,
              nomeCompleto: `${response.data.venditore.nome || ""}`.trim() || "Venditore sconosciuto"
            }
          });
          console.log("Dati Annuncio:", response.data); // 👈 Debug per verificare i dati ricevuti

        setIsLoading(false);
      } catch (err) {
        console.error("Errore nel recuperare i dettagli dell'annuncio:", err);
        setError("Impossibile caricare i dati dell'annuncio.");
        setIsLoading(false);
      }
    };

    fetchAdDetails();
  }, [annuncio_id]);

  if (isLoading) return <div>Caricamento...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      {/* Sezione Immagine - Non ha padding */}
      <AdMobileImageWrapper>
        <AdImgPageMobile images={adData.images_urls} />
      </AdMobileImageWrapper>


    <AdMobilePageWrapper>

      {/* Sezione Info */}
      <AdMobileInfo
        title={adData.titolo}
        host={{
          username: adData.venditore?.username || "Venditore sconosciuto",
          avatar: adData.venditore?.avatar || "https://via.placeholder.com/48",
          is_privato: adData.venditore?.is_privato || false,
          citta: adData.venditore?.citta || "Località non disponibile",
          numero_di_recensioni: adData.venditore?.numero_di_recensioni || 0,
          media_valutazioni: adData.venditore?.media_valutazioni || "Nessuna valutazione",
        }}
        details={[]}
        description={adData.descrizione}
      />

      {/* Sezione Dettagli */}
      <AdMobileDetails
        condizioni={adData.condizioni}
        spedizione={adData.spedizione_digitale}
        reso={adData.reso_oggetto}
      />

      {/* Badge del Venditore */}
      <SellerMobileBadge venditore={adData.venditore} />

      {/* Form di contatto */}
      <ContactFormMobile price={adData.prezzo} title={adData.titolo} />

      {/* Mappa dell'annuncio */}
      <AdMobileMap
        latitudine={adData.venditore?.latitudine}
        longitudine={adData.venditore?.longitudine}
        citta={adData.venditore?.citta}
      />

      {/* Annunci correlati */}
      <AdMobileRelateds annuncioId={annuncio_id} />
    </AdMobilePageWrapper>
  </>
  );
};

export default AdMobilePage;
