import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../context/UserContext";
import { useAnnuncio } from "../context/AnnuncioContext";
import useModalNavigation from "../utils/modalNavigation";


// Styled Components
const DropdownWrapper = styled.div`
  display: flex;
  width: 264px;
  height: auto;
  padding: 19px 0.5px 10px 0.5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: 3px 2px 4px 0px rgba(0, 0, 0, 0.06);
  z-index: 1000;  // 🔄 Aggiungi z-index alto per sovrapporre AdDetailChat
`;


const WrapperMenu = styled.div`
  display: flex;
  width: 229px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  .menu-item {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BreakLine = styled.div`
  width: 263px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const UserMenuDropdown = ({ onClose, onOpenLoginForm, onOpenRegisterForm, handleMessaggiClick }) => {
  const { user, handleLogout } = useUser();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { startCreatingAnnuncio } = useAnnuncio();
  const location = useLocation();
  const openModal = useModalNavigation();


  // Chiude il dropdown se si clicca fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <DropdownWrapper ref={dropdownRef}>
      {user?.isLoggedIn ? (
        <>
          <WrapperMenu>
            <div
              className="menu-item"
              onClick={() => {
                startCreatingAnnuncio();
                navigate("/crea-annuncio"); // ✅ Naviga alla pagina corretta
                onClose();
              }}
            >
              Inserisci annuncio
            </div>

            <div
              className="menu-item"
              onClick={() => {
                navigate("/gestisci-annunci"); // 🔹 Naviga alla pagina Gestisci Annunci
                onClose();
              }}
            >
              Gestisci Annunci
            </div>
            <div className="menu-item">Annunci Seguiti</div>
            <div
              className="menu-item"
              onClick={() => {
                if (handleMessaggiClick) {
                handleMessaggiClick();  // 🔹 Chiama la funzione se esiste
                }
                navigate("/messaggi"); // 🔹 Naviga alla pagina Messaggi
                onClose();
              }}
            >
              Messaggi
            </div>
          </WrapperMenu>

          <BreakLine />

          <WrapperMenu>
            <div className="menu-item">Il mio account</div>
            <div className="menu-item">Help</div>
            <div className="menu-item" 
                onClick={() => {
                  onClose(); // 🔹 Chiude il menu dropdown
                  handleLogout(); // 🔄 Esegue il logout
                }}>
              Esci
            </div>

          </WrapperMenu>
        </>
      ) : (
        <>
          <WrapperMenu>
            <div
              className="menu-item"
                onClick={() => {
                  onClose(); // chiude dropdown
                  openModal("/login"); // apre modale login
                }}
            >
              Accedi
            </div>
            <div
              className="menu-item"
                onClick={() => {
                  onClose();
                  openModal("/registrati"); // apre modale registrazione
                }}
            >
              Registrati
            </div>
          </WrapperMenu>


          <BreakLine />

          <WrapperMenu>
            <div className="menu-item">Vendi su Perstudenti</div>
            <div className="menu-item">Centro Assistenza</div>
          </WrapperMenu>
        </>
      )}
    </DropdownWrapper>
  );
};

export default UserMenuDropdown;
