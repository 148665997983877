import React from "react";
import styled from "styled-components";

// Wrapper fissato in alto a tutta larghezza
const FixedImageWrapper = styled.div`
  position: relative; // ✅ Fissa l'elemento in alto
  top: 0;
  left: 0;
  width: 100vw; // ✅ Occupa tutta la larghezza dello schermo
  height: 250px; // ✅ Altezza fissa per un buon layout
  background: #fff;
  z-index: 900; // ✅ Per mantenere la gerarchia degli elementi
  display: flex;
  justify-content: center;
  align-items: center;
`;


const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // ✅ Per adattare l'immagine senza distorsioni
  border-radius: 0; // ✅ Rimuove eventuali arrotondamenti
`;

// Styled Components
const AdImgPageMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const MainImgWrapper = styled.div`
  width: 100%;
  max-width: 420px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const ThumbnailsWrapper = styled.div`
  display: flex;
  gap: 6px;
  overflow-x: auto;
  width: 100%;
  max-width: 420px;
  padding-top: 6px;
  
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Thumbnail = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const AdImgPageMobile = ({ images = [] }) => {
  return (
    <FixedImageWrapper>
    <AdImgPageMobileWrapper>
      {/* Immagine principale */}
      <MainImgWrapper>
        <ImageStyled src={images[0] || "https://via.placeholder.com/420x250"} alt="Main Image" />
      </MainImgWrapper>

      {/* Miniature immagini */}
      <ThumbnailsWrapper>
        {images.slice(1, 5).map((img, index) => (
          <Thumbnail key={index} src={img || "https://via.placeholder.com/90x90"} alt={`Thumbnail ${index + 1}`} />
        ))}
      </ThumbnailsWrapper>
    </AdImgPageMobileWrapper>
    </FixedImageWrapper>
  );
};

export default AdImgPageMobile;
