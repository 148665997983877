import React from "react";
import Popup from "./Popup";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

const ConfirmUserModal = ({ open, handleClose, success, error, loading }) => {
    if (!open) return null; // Se il popup non è aperto, non renderizzare nulla

    // Determiniamo l'animazione da mostrare
    const animationType = loading
        ? "loading"  // 🔹 Animazione di caricamento (es. un'icona di attesa)
        : success
        ? "success"  // 🔹 Animazione di successo (es. un check verde)
        : "error";   // 🔹 Animazione di errore (es. una X rossa)

    // Determiniamo il testo da mostrare
    const message = loading
        ? "Verifica in corso..."
        : success
        ? "Il tuo utente è confermato. Benvenuto su Perstudenti.com! 🎉"
        : error || "Si è verificato un errore. Riprova più tardi.";

    return (
        <Popup
            animationType={animationType}
            text={message}
            buttonLabel="Ok"
            onButtonClick={handleClose}
        />
    );
};

ConfirmUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    success: PropTypes.bool,
    error: PropTypes.string,
    loading: PropTypes.bool,
};

export default ConfirmUserModal;
