import { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "../context/UserContext";
import axiosInstance from "../api/axiosConfig";

const MessaggiContext = createContext();

export const MessaggiProvider = ({ children }) => {
  const { user } = useUser(); // ✅ Recuperiamo i dati dell'utente loggato
  const [conversazioni, setConversazioni] = useState([]); // Stato per conservare le conversazioni
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * 📌 Inizia una conversazione con il venditore di un annuncio
   * @param {number} annuncioId - ID dell'annuncio per cui si avvia la conversazione
   * @param {string} testoMessaggio - Testo del primo messaggio (opzionale)
   */
  const iniziaConversazione = async (annuncioId, testoMessaggio = "") => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.post(`/inizia_conversazione/${annuncioId}/`, {
        testo: testoMessaggio, // Passa il messaggio solo se fornito
      });

      console.log("📩 Conversazione avviata con successo:", response.data);
      
      // Aggiunge la conversazione all'elenco locale
      setConversazioni((prev) => [...prev, { id: response.data.conversazione_id, annuncioId }]);

      return response.data.conversazione_id; // Restituisce l'ID della conversazione
    } catch (error) {
      console.error("❌ Errore nell'avviare la conversazione:", error.response?.data || error);
      setError("Impossibile avviare la conversazione.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * 📌 Recupera la lista delle conversazioni dell'utente loggato
   * @param {string} filter - Filtro per le conversazioni (default: "all")
   */
const fetchConversazioni = async (filter = "all") => {
    if (!user || !user.userId) {
        console.warn("⚠️ Nessun user ID trovato, impossibile recuperare le conversazioni.");
        return;
    }

    setLoading(true);
    setError(null);
    
    try {
        const response = await axiosInstance.get(`/lista_conversazioni/${user.userId}/?filter=${filter}`);
        console.log("📩 Conversazioni recuperate:", response.data);  // 🔄 Log delle conversazioni ricevute
        setConversazioni(response.data);
    } catch (error) {
        console.error("❌ Errore nel recupero delle conversazioni:", error.response?.data || error);
        setError("Errore nel caricamento delle conversazioni.");
    }
    setLoading(false);
};


  /**
   * 📩 Funzione per inviare un nuovo messaggio in una conversazione
   * @param {number} conversazioneId - ID della conversazione
   * @param {string} testoMessaggio - Testo del messaggio da inviare
   */
  const inviaMessaggio = async (conversazioneId, testoMessaggio) => {
    try {
      const response = await axiosInstance.post(`/invia_messaggio/${conversazioneId}/`, {
        testo: testoMessaggio,
      });
      console.log("📩 Messaggio inviato:", response.data);
      return response.data;  // ✅ Restituisci il messaggio inviato
    } catch (error) {
      console.error("❌ Errore nell'invio del messaggio:", error.response?.data || error);
      return null;  // Restituisce null in caso di errore
    }
  };



  /**
   * 📌 Effettua il fetch delle conversazioni al caricamento dell'utente
   */
  useEffect(() => {
    if (user && user.userId) {
      console.log("🔄 Fetching conversazioni per user:", user.userId);
      fetchConversazioni(); // Recupera le conversazioni solo se l'utente è autenticato
    }
  }, [user]); // ✅ Si aggiorna automaticamente quando `user` cambia

  return (
    <MessaggiContext.Provider
      value={{
        conversazioni,
        loading,
        error,
        iniziaConversazione,
        fetchConversazioni,
        inviaMessaggio, // ✅ Funzione accessibile ai componenti
      }}
    >
      {children}
    </MessaggiContext.Provider>
  );
};

// Hook personalizzato per usare il contesto
export const useMessaggi = () => useContext(MessaggiContext);
