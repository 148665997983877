import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api";

// 🔄 Crea un'istanza Axios SOLO per chiamate pubbliche
const axiosPublic = axios.create({
    baseURL: BASE_URL,
    withCredentials: false, // 🔥 Nessun cookie per chiamate pubbliche
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

// 🔄 Logga le richieste per debug
axiosPublic.interceptors.request.use((config) => {
    console.log("📢 Chiamata API pubblica:", config.url);
    return config;
}, (error) => Promise.reject(error));

// 🔄 Logga le risposte per debug
axiosPublic.interceptors.response.use((response) => {
    console.log("✅ Risposta API pubblica:", response);
    return response;
}, (error) => {
    console.error("❌ Errore API pubblica:", error.response || error.message);
    return Promise.reject(error);
});

export default axiosPublic;
