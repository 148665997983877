import React, { useState } from 'react';
import RoundButton from '../../RoundButton';
import { Menu, MenuItem, TextField, List, ListItem, Typography, Avatar, IconButton, Badge, Drawer } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { useAnnuncio } from '../../../context/AnnuncioContext';



const SidebarContainer = styled.div`
    padding: 16px;
    background-color: #fff;
    border-right: 1px solid #e0e0e0;
    height: 100vh;
    overflow-y: auto;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const Title = styled(Typography)`
    font-size: 20px;
    font-weight: bold;
`;

const FilterButton = styled.button`
    border: none;
    border-radius: 20px;
    padding: 6px 12px;
    margin-right: 8px;
    background-color: ${(props) => (props.selected ? '#000' : '#f0f0f0')};
    color: ${(props) => (props.selected ? '#fff' : '#000')};
    cursor: pointer;
    font-size: 14px;
`;

const FiltersContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    overflow-x: auto;
    padding-bottom: 8px;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 4px 8px;
    margin-bottom: 16px;
`;

const SearchInput = styled.input`
    border: none;
    background-color: transparent;
    outline: none;
    flex-grow: 1;
    padding: 4px 8px;
    font-size: 14px;
`;

const StyledListItem = styled(ListItem)`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 8px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    &:hover {
        background-color: #f9f9f9;
    }
`;

const AvatarWrapper = styled.div`
    flex-shrink: 0;
`;

const MessageInfo = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const MessageTitle = styled(Typography)`
    font-size: 14px;
    font-weight: 600;
    color: #333;
`;

const MessagePreview = styled(Typography)`
    font-size: 12px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MessageDate = styled(Typography)`
    font-size: 12px;
    color: #999;
    text-align: right;
`;

const SidebarMobile = ({ conversazioni, selectedMessage, setSelectedMessage }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('all');
    
    

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

const filteredConversazioni = conversazioni.filter((msg) => {
    const matchesSearch = msg.venditore?.nome?.toLowerCase().includes(searchQuery.toLowerCase()) || false;

    const matchesFilter =
        selectedFilter === 'all' ||
        (selectedFilter === 'received' && msg.tipo === 'received') ||
        (selectedFilter === 'sent' && msg.tipo === 'sent') ||
        (selectedFilter === 'unread' && msg.nonLetti > 0);

    return matchesSearch && matchesFilter;
});




    return (
        <SidebarContainer>
            <Header>
                <Title>Messaggi</Title>
            </Header>

            <FiltersContainer>
<FilterButton selected={selectedFilter === 'all'} onClick={() => handleFilterClick('all')}>
    Tutti
</FilterButton>
<FilterButton selected={selectedFilter === 'received'} onClick={() => handleFilterClick('received')}>
    Ricevuti
</FilterButton>
<FilterButton selected={selectedFilter === 'sent'} onClick={() => handleFilterClick('sent')}>
    Inviati
</FilterButton>
<FilterButton selected={selectedFilter === 'unread'} onClick={() => handleFilterClick('unread')}>
    Non letti
</FilterButton>

            </FiltersContainer>

            <SearchContainer>
                <SearchIcon style={{ color: '#999' }} />
                <SearchInput
                    placeholder="Cerca un annuncio..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </SearchContainer>

            <List>
                {filteredConversazioni.map((msg) => (
                    <StyledListItem
                        key={msg.id}
                        selected={selectedMessage?.id === msg.id}
                        onClick={() => setSelectedMessage(msg)}
                    >
                        <AvatarWrapper>
                            <Badge
                                color="primary"
                                badgeContent={msg.nonLetti}
                                invisible={msg.nonLetti === 0}
                            >
                                <Avatar src={msg.venditore?.avatar || "https://via.placeholder.com/50"} />
                            </Badge>
                        </AvatarWrapper>
                        <MessageInfo>
                            <MessageTitle>{msg.venditore?.nome || "Venditore sconosciuto"}</MessageTitle>
                            <MessagePreview>{msg.ultimo_messaggio || "Nessun messaggio"}</MessagePreview>
                        </MessageInfo>
                        <MessageDate>
                            {new Date(msg.data_ultimo_messaggio).toLocaleDateString('it-IT', {
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit'
                            })}
                        </MessageDate>
                    </StyledListItem>
                ))}
                {filteredConversazioni.length === 0 && (
                    <Typography variant="body2" color="textSecondary" align="center">
                        Nessuna conversazione trovata
                    </Typography>
                )}
            </List>
        </SidebarContainer>
    );
};

export default SidebarMobile;