import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useAnnuncio } from "../../context/AnnuncioContext";
import { useParams } from "react-router-dom";
import { useUser } from "../../context/UserContext";



//Componenti Sezione della pagina
import AdImgPage from "./AdImgPage";
import AdInfo from "./AdInfo";
import ContactForm from "./ContactForm";
import SellerBadge from "./SellerBadge";
import AdDetails from "./AdDetails";
import AdRelateds from "./AdRelateds";
import AdMap from "./AdMap";
import Footer from "./Footer";
import AdNavbar from "./AdNavbar";
//import axios from "../../api/axiosConfig";
import ShareIcon from "../../assets/icons/share.svg";
import FollowIcon from "../../assets/icons/follow.svg";
import SendMessage from "./SendMessage";

//Gestione componente Mobile
import AdMobilePage from "./Mobile/AdMobilePage"; // Import del componente mobile

// Styled Components
const AdPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 16px;
  text-align: left;
`;

const TitleText = styled.h2`
  font-family: "PP Gosha Sans";
  font-size: 40px;
  font-weight: 700;
  color: #000;
  text-align: left;
  flex: 1;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    color: #000;
    font-family: "PP Gosha Sans";
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
  }
`;

const FollowButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    color: #000;
    font-family: "PP Gosha Sans";
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.palette.divider};
  margin: 24px 0;
`;

const AdContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;
`;

const AdInfoWrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: left;
`;

const ContactFormWrapper = styled.div`
  flex: 1;
  position: ${(props) => (props.sticky ? "sticky" : "relative")};
  top: ${(props) => (props.sticky ? "72px" : "0px")};
  align-self: flex-start;
  text-align: left;
`;

const AdMapWrapper = styled.div`
  margin-top: 48px;
  text-align: left;
`;

const AdRelatedsWrapper = styled.div`
  margin-top: 48px;
  text-align: left;
`;

const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.background.default};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000; /* Porta in primo piano */
`;

const AdPage = () => {
    const { annuncio_id } = useParams();
    console.log("📌 ID Annuncio da useParams:", annuncio_id);
    const { annuncio, loadingAnnuncio, error, fetchAnnuncioCorrente } = useAnnuncio();
    const { user } = useUser();
    const isOwner = annuncio?.is_owner;
    const [showNavbar, setShowNavbar] = useState(false);
    const [showSendMessage, setShowSendMessage] = useState(false); 
    const [showButton, setShowButton] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const imgSectionRef = useRef(null);
    const buttonRef = useRef(null);
    const infoSectionRef = useRef(null);


    // Effetto per rilevare il resize della finestra
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      if (annuncio_id) {
        console.log(`📌 AdPage: ID Annuncio dalla URL: ${annuncio_id}`);
        fetchAnnuncioCorrente(annuncio_id); // ✅ Chiamata per recuperare l'annuncio
      }
    }, [annuncio_id]); // Ricarica se cambia l'ID

    useEffect(() => {
        const handleScroll = () => {
            if (imgSectionRef.current) {
                const rect = imgSectionRef.current.getBoundingClientRect();
                setShowNavbar(rect.top < 0);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (!buttonRef.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => setShowButton(entry.isIntersecting),
            { root: null, threshold: 0 }
        );

        observer.observe(buttonRef.current);
        return () => observer.disconnect();
    }, []);

    if (loadingAnnuncio) return <div>Caricamento...</div>;
    if (error || !annuncio) return <div>Errore: Annuncio non disponibile</div>;



    // ✅ Se `showSendMessage` è attivo, mostriamo solo SendMessage a tutto schermo
    if (showSendMessage) {
        return (
            <FullscreenWrapper>
                <SendMessage
                    host={{
                        nome: annuncio.venditore?.nomeCompleto,
                        avatar: annuncio.venditore?.avatar || "https://via.placeholder.com/50",                    }}
                    onClose={() => setShowSendMessage(false)} // 👈 Permette di chiudere e tornare alla pagina normale
                />
            </FullscreenWrapper>
        );
    }




    // Se è mobile, renderizza il componente AdMobilePage
    if (isMobile) {
        return <AdMobilePage adData={annuncio} />;
    }

    return (
        <AdPageWrapper>
            {/* Navbar con riferimenti alle sezioni */}
            {showNavbar && (
                <AdNavbar
                    title={annuncio.titolo}
                    price={annuncio.prezzo}
                    showButton={!showButton}
                    imgSectionRef={imgSectionRef} // 👈 Passiamo il riferimento alla sezione immagini
                    infoSectionRef={infoSectionRef} // 👈 Passiamo il riferimento alla sezione info
                />
            )}

            {/* Titolo e azioni */}
            <TitleWrapper>
                <TitleText>{annuncio.titolo}</TitleText>
                <ActionButtonsWrapper>
                    <ShareButton>
                        <img src={ShareIcon} alt="Condividi" />
                        <span>Condividi</span>
                    </ShareButton>
                    <FollowButton>
                        <img src={FollowIcon} alt="Segui" />
                        <span>Segui</span>
                    </FollowButton>
                </ActionButtonsWrapper>
            </TitleWrapper>

            <Divider />

            {/* Sezione immagini */}
            <div ref={imgSectionRef}>
                <AdImgPage images={annuncio.images_urls} />
            </div>

            <Divider />

            {/* Sezione info annuncio e ContactForm */}
            <AdContent ref={infoSectionRef}>
                <AdInfoWrapper>
                  <AdInfo
                      title={annuncio.titolo}
                      venditore={{
                          username: annuncio.venditore?.username || "Venditore sconosciuto",
                          avatar: annuncio.venditore?.avatar || "https://via.placeholder.com/48",
                          is_privato: annuncio.venditore?.is_privato || false,
                          citta: annuncio.venditore?.citta || "Località non disponibile",
                          numero_di_recensioni: annuncio.venditore?.numero_di_recensioni || 0,
                          media_valutazioni: annuncio.venditore?.media_valutazioni || "Nessuna valutazione",
                      }}
                      details={[]}
                      description={annuncio.descrizione}
                  />
                    <Divider />
                      <AdDetails
                          condizioni={annuncio.condizioni}
                          spedizione={annuncio.spedizione_digitale ? "Sì" : "No"} // Usa il campo corretto
                          reso={annuncio.reso_oggetto ? "Sì" : "No"} // Usa `reso_oggetto` dal modello
                      />
                    <Divider />
                      <SellerBadge venditore={annuncio.venditore} />
                </AdInfoWrapper>

                <ContactFormWrapper sticky={showNavbar}>
                    {isOwner ? (
                      <h3>✅ Questo annuncio è tuo</h3>
                    ) : (
                    <ContactForm 
                    buttonRef={buttonRef} 
                    price={annuncio.prezzo} 
                    title={annuncio.titolo} 
                    onContactClick={() => setShowSendMessage(true)}
                    />
                    )}
                </ContactFormWrapper>
            </AdContent>

            <Divider />

            {/* Mappa */}
            <AdMapWrapper>
                <AdMap 
                  latitudine={annuncio.venditore?.latitudine} 
                  longitudine={annuncio.venditore?.longitudine} 
                  citta={annuncio.venditore?.citta}
                />
            </AdMapWrapper>

            <Divider />

            {/* Opzioni correlate */}
            <AdRelatedsWrapper>
                <AdRelateds annuncioId={annuncio_id} />
            </AdRelatedsWrapper>

            <Divider />

            {/* Footer */}
            <Footer />
        </AdPageWrapper>
    );
};

export default AdPage;
