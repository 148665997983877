import React from "react";
import styled from "styled-components";
import SellerImage from "../../../assets/icons/user.svg"; // Immagine di default

// Styled Components
const AdMobileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`;

const HostInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HostAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc; /* Placeholder */
`;

const HostDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const HostName = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const HostMeta = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
`;

const IconWrapper = styled.span`
  font-size: 16px;
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  color: ${(props) => props.theme.palette.text.primary};
`;

const AdMobileInfo = ({ title, venditore = {}, details = [], description }) => {
  return (
    
    <AdMobileInfoWrapper>
    {/*  
      <HostInfo>
        <HostAvatar src={venditore.avatar || SellerImage} alt={venditore.nomeCompleto} />
        <HostDetails>
          <HostName>{venditore.nomeCompleto || "Venditore sconosciuto"} - {venditore.is_privato ? "Privato" : "Azienda"}</HostName>
        </HostDetails>
      </HostInfo> */}

     {/* {/* Dettagli 
      <DetailsList>
        <Title>{title}</Title>
        {Array.isArray(details) && details.length > 0 ? (
          details.map((detail, index) => (
            <DetailItem key={index}>
              <IconWrapper>{detail.icon}</IconWrapper>
              <span>{detail.text}</span>
            </DetailItem>
          ))
        ) : (
          <DetailItem>
            <span>Dettagli non disponibili</span>
          </DetailItem>
        )}
      </DetailsList> */}

      {/* Descrizione */}
      <Description>{description || "Descrizione non disponibile"}</Description>
    </AdMobileInfoWrapper>
  );
};

export default AdMobileInfo;
