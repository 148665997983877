import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // ✅ import useNavigate
import styled from "styled-components";
import { useUser } from "../../context/UserContext"; // Import del context utente

import SearchIcon from "../../assets/icons/search.svg";
import FollowIcon from "../../assets/icons/follow.svg";
import UserIcon from "../../assets/icons/user.svg";
import MessageIcon from "../../assets/icons/message.svg"; // Assicurati che esista

import LoginFormMobile from "../Mobile/LoginFormMobile";

// Styled Components (rimangono invariati)
const NavbarMobileWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`;

const CardButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 67px;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.active ? props.theme.palette.primary.main : "transparent"};
    transition: background-color 0.3s ease;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .text {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    text-align: center;
    margin-top: 4px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const NavbarMobileBottom = () => {
  const { user } = useUser(); // ✅ ottieni lo stato login dal context
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("trova");
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);

  const handleLoginClick = () => {
    if (user.isLoggedIn) {
      setActiveButton("profilo");
      navigate("/area-riservata"); // ✅ naviga al componente AreaRiservataMobile
    } else {
      setActiveButton("accedi");
      setIsLoginFormOpen(true);
    }
  };

  const closeLoginForm = () => setIsLoginFormOpen(false);

    const handleNavigate = (button, path) => {
    setActiveButton(button);
    navigate(path);
  };

  return (
    <>
      <NavbarMobileWrapper>
        {/* Pulsante Trova */}
        <CardButton
          active={activeButton === "trova"}
          onClick={() => handleNavigate("trova", "/")}
        >
          <div className="icon">
            <img src={SearchIcon} alt="Trova" />
          </div>
          <div className="text">Trova</div>
        </CardButton>

        {/* Pulsante Seguiti */}
        <CardButton
          active={activeButton === "seguiti"}
          onClick={() => handleNavigate("seguiti", "/seguiti")}
        >
          <div className="icon">
            <img src={FollowIcon} alt="Seguiti" />
          </div>
          <div className="text">Seguiti</div>
        </CardButton>

        {/* Pulsante Messaggi (visibile solo se loggato) */}
        {user.isLoggedIn && (
          <CardButton
            active={activeButton === "messaggi"}
            onClick={() => handleNavigate("messaggi", "/messaggi")}
          >
            <div className="icon">
              <img src={MessageIcon} alt="Messaggi" />
            </div>
            <div className="text">Messaggi</div>
          </CardButton>
        )}

        {/* Pulsante Accedi/Profilo */}
        <CardButton
          active={activeButton === (user.isLoggedIn ? "profilo" : "accedi")}
          onClick={handleLoginClick}
        >
          <div className="icon">
            <img src={UserIcon} alt={user.isLoggedIn ? "Profilo" : "Accedi"} />
          </div>
          <div className="text">{user.isLoggedIn ? "Profilo" : "Accedi"}</div>
        </CardButton>
      </NavbarMobileWrapper>

      {/* Modal Login solo se non loggato */}
      {isLoginFormOpen && !user.isLoggedIn && (
        <ModalOverlay onClick={closeLoginForm}>
          <div onClick={(e) => e.stopPropagation()}>
            <LoginFormMobile onClose={closeLoginForm} />
          </div>
        </ModalOverlay>
      )}
    </>
  );
};

export default NavbarMobileBottom;
