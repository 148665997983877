import React from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled from "styled-components";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

// 🎨 Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 90px; // aggiungi padding-bottom sufficiente
  overflow-y: auto; // permette di scorrere se il contenuto supera lo schermo
`;

const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProfileBox = styled(Box)`
  width: 100%;
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 16px;
`;

const AvatarPlaceholder = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #e0e0e0;
`;

const ButtonAnnuncio = styled(Box)`
  width: 100%;
  background: white;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
`;

const ImgContainer = styled(Box)`
  width: 80px;
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const MenuItem = styled(Box)`
  width: 100%;
  background: white;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
`;

const MenuIconPlaceholder = styled(Box)`
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 4px;
`;

const LogoutButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
  padding: 10px 0;
  background-color: #fff !important;
  color: black !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05) !important;
`;

const AreaRiservataMobile = () => {
  const { user, handleLogout } = useUser();
  const navigate = useNavigate();

  return (
    <Container>
      {/* Intestazione */}
      <Header>
        <Typography variant="h5" fontWeight="bold">
          Profilo
        </Typography>
        <IconButton>
          <NotificationsIcon />
        </IconButton>
      </Header>

      {/* Box Profilo */}
      <ProfileBox>
        <AvatarPlaceholder />
        <Typography variant="h6" fontWeight="bold">
          {user.username || "Nome"}
        </Typography>
      </ProfileBox>

      {/* Inserisci Annuncio */}
      <ButtonAnnuncio onClick={() => navigate("/crea-annuncio")}>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1" fontWeight="bold">
            Inserisci Annuncio
          </Typography>
          <Typography variant="body2">
            È facile iniziare a vendere e guadagnare qualcosa in più
          </Typography>
        </Box>
        <ImgContainer>Img Container</ImgContainer>
      </ButtonAnnuncio>

      {/* Voci del menu */}
      <MenuItem onClick={() => navigate("/gestisci-annunci")}>
        <Box display="flex" alignItems="center" gap="12px">
          <MenuIconPlaceholder />
          <Typography variant="body1" fontWeight="bold">Gestisci Annunci</Typography>
        </Box>
        <ChevronRightIcon />
      </MenuItem>

      <MenuItem onClick={() => navigate("/annunci-seguiti")}>
        <Box display="flex" alignItems="center" gap="12px">
          <MenuIconPlaceholder />
          <Typography variant="body1" fontWeight="bold">Annunci Seguiti</Typography>
        </Box>
        <ChevronRightIcon />
      </MenuItem>

      <MenuItem onClick={() => navigate("/mio-account")}>
        <Box display="flex" alignItems="center" gap="12px">
          <MenuIconPlaceholder />
          <Typography variant="body1" fontWeight="bold">Il mio account</Typography>
        </Box>
        <ChevronRightIcon />
      </MenuItem>

      {/* Logout */}
      <LogoutButton 
        variant="contained" 
        onClick={() => {
          console.log("🔄 Logout in corso...");
          handleLogout();
        }}
      >
        Esci
      </LogoutButton>

    </Container>
  );
};

export default AreaRiservataMobile;
