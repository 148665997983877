import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useAnnuncio } from "../context/AnnuncioContext";

const DialogExitConfirmation = () => {
  const { isExitDialogOpen, handleExitConfirmed, handleExitCancelled } = useAnnuncio();

  return (
    <Dialog open={isExitDialogOpen} onClose={handleExitCancelled}>
      <DialogTitle>Attenzione</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sei sicuro di voler uscire? Tutte le modifiche non salvate andranno perse.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleExitCancelled} color="primary">Annulla</Button>
        <Button onClick={handleExitConfirmed} color="error">Esci</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogExitConfirmation;
