import React from "react";
import styled from "styled-components";
import SellerImage from "../../../assets/icons/user.svg"; // Immagine di default

// Styled Components
const SellerMobileBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;

const SellerCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #fff;
  border-radius: 10px;
`;

const SellerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SellerAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const SellerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SellerName = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 14px;
  font-weight: 700;
`;

const SellerRole = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const SellerFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const RatingText = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const ReviewText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

// Componente Icon per la valutazione con stella
const Icon = styled.svg`
  width: 14px;
  height: 14px;
  fill: ${(props) => props.theme.palette.primary.main};
`;

const SellerMobileBadge = ({ venditore }) => {
  if (!venditore) {
    return null;
  }

  return (
    <SellerMobileBadgeWrapper>
      {/* Card del venditore */}
      <SellerCard>
        <SellerInfo>
          <SellerAvatar src={venditore.avatar || SellerImage} alt={venditore.nomeCompleto} />
          <SellerDetails>
            <SellerName>{venditore.nomeCompleto || "Venditore"}</SellerName>
            <SellerRole>{venditore.is_azienda ? "Azienda" : "Privato"}</SellerRole>
          </SellerDetails>
        </SellerInfo>

        {/* Sezione Valutazioni */}
        <SellerFeedbackWrapper>
          <RatingText>
            {venditore.valutazioni ? (
              <>
                <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12 2L14.6142 8.09619L21.364 8.95492L16.682 13.1078L18.2284 19.7951L12 16.5738L5.77163 19.7951L7.31801 13.1078L2.63599 8.95492L9.38583 8.09619L12 2Z" />
                </Icon>{" "}
                {venditore.valutazioni} ★
              </>
            ) : (
              "Nessuna valutazione"
            )}
          </RatingText>
          <ReviewText>{venditore.numero_di_recensioni || 0} recensioni</ReviewText>
        </SellerFeedbackWrapper>
      </SellerCard>
    </SellerMobileBadgeWrapper>
  );
};

export default SellerMobileBadge;
