import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SearchIcon from "../assets/icons/search.svg";
import axiosInstance from "../api/axiosConfig"; // Assicurati di importarlo

// Styled Components
const NavbarBottom = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchbarWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid #d9d9d9;
  border-radius: 32px;
  box-shadow: ${(props) =>
    props.isFocused
      ? "0px 10px 20px rgba(0, 0, 0, 0.25)"
      : "0px 2px 11px 2px rgba(0, 0, 0, 0.15)"};
  height: 71px;
  width: 934px;
  padding: 0 20px;
  justify-content: space-between;
  transform: ${(props) => (props.isFocused ? "scale(1.05)" : "scale(1)")};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

const InputField = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.primary};
  width: 100%;

  &::placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 18px;
    font-weight: 400;
  }
`;

const IconWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  border: none;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;

const NavbarBottomComponent = () => {
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();

const handleSearch = async () => {
  if (query.trim() !== "") {
    console.log("🔄 Sto cercando:", query.trim());

    const newUrl = `/annunci?q=${encodeURIComponent(query.trim())}`;
    console.log("🔄 Navigazione a URL:", newUrl);

    // 🔹 Usa `navigate()` SENZA `replace: false` per non perdere lo stato
    navigate(newUrl);

    // 🔹 Poi usa `window.history.pushState()` per forzare la query string
    setTimeout(() => {
      console.log("🧐 PRIMA della sostituzione, URL attuale:", window.location.href);
      window.history.pushState({}, "", newUrl);
      console.log("✅ URL aggiornato manualmente con pushState:", window.location.href);
    }, 300);
  }
};







  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <NavbarBottom>
      <SearchbarWrapper isFocused={isFocused}>
        <InputField
          type="text"
          placeholder={isFocused ? "" : "Cerca un annuncio..."}
          value={query}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSearch();}}}
        />
        <IconWrapper onClick={handleSearch}>
          <img src={SearchIcon} alt="Search Icon" />
        </IconWrapper>
      </SearchbarWrapper>
    </NavbarBottom>
  );
};

export default NavbarBottomComponent;
