// utils/modalNavigation.js
import { useNavigate, useLocation } from "react-router-dom";

const useModalNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openModalRoute = (path) => {
    navigate(path, {
      state: { backgroundLocation: location },
    });
  };

  return openModalRoute;
};

export default useModalNavigation;
