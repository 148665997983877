import React from "react";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Styled Components
const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const LocationText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 8px;
`;

const ShowMoreButton = styled.a`
  margin-top: 8px;
  background: none;
  border: none;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration-thickness: 2px;
  }
`;

const StyledMapContainer = styled(MapContainer)`
  height: 250px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

// Component
const AdMobileMap = ({ latitudine, longitudine, citta }) => {
  if (!latitudine || !longitudine) {
    return (
      <MapWrapper>
        <Title>Dove si trova l'annuncio</Title>
        <LocationText>Località non disponibile</LocationText>
      </MapWrapper>
    );
  }

  return (
    <MapWrapper>
      <Title>Dove si trova l'annuncio</Title>
      <LocationText>{citta || "Località non specificata"}</LocationText>

      {/* Mappa OpenStreetMap con Leaflet */}
      <StyledMapContainer center={[latitudine, longitudine]} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[latitudine, longitudine]}>
          <Popup>{citta || "Località"}</Popup>
        </Marker>
      </StyledMapContainer>

      {/* Link a OpenStreetMap */}
      <ShowMoreButton
        href={`https://www.openstreetmap.org/?mlat=${latitudine}&mlon=${longitudine}#map=15/${latitudine}/${longitudine}`}
        target="_blank"
      >
        Mostra su OpenStreetMap
      </ShowMoreButton>
    </MapWrapper>
  );
};

export default AdMobileMap;
