import React, { useState } from "react";
import styled from "styled-components";
import { useAnnuncio } from "../../context/AnnuncioContext";
import { useMessaggi } from "../../context/MessaggiContext";
import RoundButton from "../RoundButton";
import FormMessage from "../Message/FormMessage";
import CardAdMessage from "../Message/cardAdMessage";
import Modal from "../Modal"; // ✅ Importiamo la modale

// Styled Components
const SendMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 24px;
  background-color: ${(props) => props.theme.palette.background.default};
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  margin-top: 24px;
  gap: 80px;
`;

const RoundButtonWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 80px;
`;

const SendMessage = ({ onClose }) => {
  const { annuncio, loadingAnnuncio } = useAnnuncio();
  const { iniziaConversazione } = useMessaggi();
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false); // ✅ Stato per la modale
  const [modalMessage, setModalMessage] = useState(""); // ✅ Messaggio della modale

  if (loadingAnnuncio) return <SendMessageWrapper>Caricamento...</SendMessageWrapper>;

  // 📌 Funzione per inviare il messaggio e iniziare la conversazione
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const conversazioneId = await iniziaConversazione(annuncio.id, message);
    if (conversazioneId) {
      setModalMessage("Messaggio inviato correttamente!"); // ✅ Imposta il messaggio della modale
      setOpenModal(true); // ✅ Mostra la modale
    } else {
      setModalMessage("Errore nell'invio del messaggio. Riprova."); // ✅ Messaggio di errore
      setOpenModal(true);
    }
  };

  // 📌 Gestione chiusura della modale
  const handleCloseModal = () => {
    setOpenModal(false); // ✅ Chiude la modale
    setMessage(""); // ✅ Resetta il messaggio
    onClose(); // ✅ Chiude il componente principale
  };

  return (
    <SendMessageWrapper>
      {/* Modale di conferma */}
      <Modal
        open={openModal}
        onClose={handleCloseModal} // ✅ Chiudi modale
        title="Conferma invio"
        description={modalMessage}
        confirmLabel="OK"
        cancelLabel=""
        onConfirm={handleCloseModal} // ✅ Chiudi modale anche su conferma
      />

      {/* Pulsante di ritorno */}
      <RoundButtonWrapper>
        <RoundButton back onClick={onClose} />
      </RoundButtonWrapper>

      {/* Contenuto principale */}
      <ContentWrapper>
        <FormMessage
          venditore={{ nome: annuncio.venditore?.nome || "Venditore sconosciuto" }}
          titoloAnnuncio={annuncio.titolo || "Titolo non disponibile"}
          message={message}
          setMessage={setMessage}
          onSendMessage={handleSendMessage} // ✅ Passiamo la funzione per inviare il messaggio
        />

        <CardAdMessage annuncio={annuncio} />
      </ContentWrapper>
    </SendMessageWrapper>
  );
};

export default SendMessage;
