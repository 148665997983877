import React, { useState, useEffect, useRef } from 'react';
import SidebarMobile from './SidebarMobile';
import ChatViewMobile from './ChatViewMobile';
import AdDetailChatMobile from './AdDetailChatMobile';
import styled from 'styled-components';
import { useMessaggi } from '../../../context/MessaggiContext';
import { useUser } from '../../../context/UserContext';
import { useAnnuncio } from '../../../context/AnnuncioContext';


// Styled Components
const Container = styled.div`
    background-color: #f5f5f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
`;

const SidebarContainer = styled.div`
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: ${(props) => (props.showSidebar ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    transform: ${(props) => (props.showSidebar ? 'translateX(0)' : 'translateX(-100%)')};
`;


const ChatContainer = styled.div`
    flex: 1;
    display: ${(props) => (props.showChat ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    transform: ${(props) => (props.showChat ? 'translateX(0)' : 'translateX(100%)')};
`;

const MessaggiMobile = () => {
    const { conversazioni, fetchConversazioni, inviaMessaggio, loading, error } = useMessaggi();
    const { user } = useUser();
    const { fetchAnnuncioCorrente } = useAnnuncio();

    const [selectedMessage, setSelectedMessage] = useState(null);
    const [annuncio, setAnnuncio] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [isAdDetailOpen, setIsAdDetailOpen] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [isAdDetailModalOpen, setIsAdDetailModalOpen] = useState(false);

    const lastAnnuncioId = useRef(null);

    // 🔄 Carica le conversazioni all'inizio
    useEffect(() => {
        fetchConversazioni();
    }, []);

    // 🔄 Seleziona la prima conversazione di default quando vengono caricate
    useEffect(() => {
        if (conversazioni.length > 0 && !selectedMessage) {
            const sortedConversazioni = [...conversazioni].sort((a, b) => 
                new Date(b.data_ultimo_messaggio) - new Date(a.data_ultimo_messaggio)
            );
            setSelectedMessage(sortedConversazioni[0]);
        }
    }, [conversazioni, selectedMessage]);

    // 🔄 Recupera i dettagli dell'annuncio quando cambia `selectedMessage`
    useEffect(() => {
        if (selectedMessage?.annuncio && selectedMessage.annuncio !== lastAnnuncioId.current) {
            fetchAnnuncioCorrente(selectedMessage.annuncio);
            lastAnnuncioId.current = selectedMessage.annuncio;
        }
    }, [selectedMessage, fetchAnnuncioCorrente]);

    const handleSelectMessage = (msg) => {
        setSelectedMessage(msg);
        setShowChat(true);        // 🔄 Mostra la chat
        setShowSidebar(false);    // 🔄 Nasconde la sidebar
    };

    const handleBackToSidebar = () => {
        setShowChat(false);       // 🔄 Mostra la sidebar
        setShowSidebar(true);     // 🔄 Nasconde la chat
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() && selectedMessage) {
            const messaggioInviato = await inviaMessaggio(selectedMessage.id, newMessage);
            if (messaggioInviato) {
                setSelectedMessage((prev) => ({
                    ...prev,
                    messaggi: [...prev.messaggi, messaggioInviato],
                }));
            }
            setNewMessage('');
        }
    };

    const handleOpenAdDetails = () => {
        setIsAdDetailModalOpen(true);   // 🔄 Apre la modale per i dettagli dell'annuncio
        setShowChat(false);             // 🔄 Nasconde la chat mentre la modale è aperta
    };


    const handleCloseAdDetails = () => {
        setIsAdDetailModalOpen(false);  // 🔄 Chiude la modale
        setShowChat(true);              // 🔄 Mostra di nuovo la chat
    };

    

    if (loading) return <div>Caricamento...</div>;
    if (error) return <div>Errore: {error}</div>;

    return (
        <Container>
            {/* Sidebar Mobile */}
            <SidebarContainer showSidebar={showSidebar}>
                <SidebarMobile
                    conversazioni={conversazioni}
                    selectedMessage={selectedMessage}
                    setSelectedMessage={handleSelectMessage}
                    setAnnuncio={setAnnuncio}
                />
            </SidebarContainer>

            {/* Chat Mobile */}
            <ChatContainer showChat={showChat}>
                <ChatViewMobile
                    selectedMessage={selectedMessage}
                    user={user}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    handleSendMessage={handleSendMessage}
                    onShowAdDetails={handleOpenAdDetails}  // 🔄 Apre la modale per i dettagli dell'annuncio
                    goBackToSidebar={handleBackToSidebar}
                />
            </ChatContainer>

            {/* Modale per i dettagli dell'annuncio */}
            {isAdDetailModalOpen && (
                <AdDetailChatMobile
                    selectedMessage={selectedMessage}
                    open={isAdDetailModalOpen}
                    onClose={handleCloseAdDetails}
                />
            )}

        </Container>
    );
};

export default MessaggiMobile;
