import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext"; // Assicurati che il percorso sia corretto
import styled from "styled-components";
import RegisterForm from "./RegisterForm";
import axiosInstance from "../api/axiosConfig"; // 🔹 Importa istanza axios
import CloseIcon from "../assets/icons/closeicon.svg";
import UserIcon from "../assets/icons/user.svg";
import LockIcon from "../assets/icons/lockIcon.svg";
import EyeIcon from "../assets/icons/eye.svg"; // Nuova icona per mostrare la password
import EyeOffIcon from "../assets/icons/eyeOff.svg"; // Icona per nascondere la password



// Styled Components

// Overlay per coprire tutta la pagina durante il caricamento
const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

// Icona di caricamento (puoi sostituirla con un'immagine se preferisci)
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const LoginFormWrapper = styled.div`
  width: 694px;
  height: 524px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 300; /* Più alto del ModalOverlay */
`;


const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CloseButton = styled.div`
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 20px;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  margin: 8px 0;
`;

const HeaderForm = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 177px;
`;

const Form = styled.div`
  width: 100%;
  height: 125px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.divider};
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FieldIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const FieldText = styled.input`
  border: ${(props) => (props.hasError ? '2px solid #D32F2F' : '2px solid transparent')};
  outline: none;
  flex: 1;
  padding: 4px;
  border-radius: 4px;


  &::placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const Conditions = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`;

const LoginButton = styled.div`
  display: flex;
  width: 100%;
  padding: 11px 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  margin-top: 16px;
`;

const TextButton = styled.div`
  color: #fff;
  font-family: "PP Gosha Sans";
  font-size: 24px;
  font-weight: 700;
`;

const WrapperRegistrati = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 32px;
  margin-top: 24px;
  width: 100%;
`;

const BreakLineL = styled.div`
  width: 209px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const BreakLineR = styled.div`
  width: 209px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer; /* 🔹 Cambia il cursore in hand cliccabile */
  transition: color 0.3s ease; /* 🔹 Transizione per l'hover */

  &:hover {
    color: ${(props) => props.theme.palette.primary.main}; /* 🔹 Colore in hover (opzionale) */
  }
`;


const TogglePasswordButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;




const LoginForm = ({ onClose }) => {
  const { handleLoginSuccess } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Nuovo stato per la visualizzazione della password
  const [lockout, setLockout] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

    // Prendi lo sfondo originale o fallback a "/"
  const backgroundLocation = location.state?.backgroundLocation || { pathname: "/" };





  // 🔹 Funzione per gestire il login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");  // 🔥 Resetta l'errore precedente

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000);  // 🔥 Timeout di 10 secondi

    try {
        const response = await axiosInstance.post("/login/", {
            username_or_email: email,
            password: password,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            signal: controller.signal,
            withCredentials: true  // ✅ Necessario per ricevere il cookie CSRF
        });

        clearTimeout(timeoutId);

        if (response.data.user) {
            handleLoginSuccess(
                response.data.user.id,
                response.data.user.username,
                response.data.user.email
            );

            console.log("🍪 Tutti i cookie dopo il login:", document.cookie);  // ✅ Logga i cookie per vedere se il CSRF è presente

            onClose();
} else {
    setError("⚠️ Credenziali non valide. Controlla l'email e la password.");
}

} catch (error) {
    console.error("❌ Errore durante il login:", error);

    if (error.name === "AbortError") {
        setError("⏳ Tempo scaduto. Verifica la connessione e riprova.");
    } else if (error.response) {
        const { status, data } = error.response;

        switch (status) {
            case 400:
                if (data?.errors) {
                    const errorMessages = Object.values(data.errors).flat().join(" ");
                    setError(`⚠️ Errore di validazione: ${errorMessages}`);
                } else {
                    setError("⚠️ Dati inseriti non validi. Controlla il formato e riprova.");
                }
                break;
            
            case 401:
                setError("🔒 Email o password errati. Verifica e riprova.");
                break;
            
            case 403:
                setError("🚫 Accesso negato. Controlla le credenziali o verifica il tuo account.");
                break;

            case 404:
                setError("❌ Servizio non disponibile. Il server non ha trovato la risorsa richiesta.");
                break;

            case 429:
                setError("⚠️ Troppe richieste. Attendi qualche minuto prima di riprovare.");
                break;

            case 500:
                setError("🛠 Errore del server. Riprova più tardi o contatta l'assistenza.");
                break;

            default:
                setError(data?.message || "❓ Errore sconosciuto. Riprova più tardi.");
          }
      } else if (error.request) {
          setError("📡 Nessuna risposta dal server. Verifica la tua connessione internet.");
      } else {
          setError("❌ Errore imprevisto. Riprova più tardi.");
      }
    } finally {
        setLoading(false);
    }
};


const handleClose = () => {
  window.location.href = "/";
};



const ErrorModal = ({ message, onClose }) => (
    <LoaderOverlay onClick={onClose}>
        <div style={{ padding: '20px', background: '#fff', borderRadius: '8px', textAlign: 'center', boxShadow: '0 0 10px rgba(0,0,0,0.3)' }}>
            <h4 style={{ margin: '0 0 10px 0', color: '#D32F2F' }}>Errore</h4>
            <p>{message}</p>
            <button onClick={onClose} style={{ marginTop: '10px', padding: '5px 10px', cursor: 'pointer' }}>Chiudi</button>
        </div>
    </LoaderOverlay>
);



  return (
<>
        {loading && (
      <LoaderOverlay>
        <Spinner />
      </LoaderOverlay>
      )}

    <LoginFormWrapper>
      <LoginFormContainer>
        <CloseButton onClick={handleClose}>
          <img src={CloseIcon} alt="Close" />
        </CloseButton>

        <Title>Accedi o Registrati</Title>
        <BreakLine />
        <HeaderForm>Ciao! Ti diamo il benvenuto su Perstudenti.com</HeaderForm>

        <FormWrapper>
          <Form>
            <FieldWrapper>
              <FieldIcon src={UserIcon} alt="User Icon" />
                <FieldText
                  type="email"
                  placeholder="Es.bolly@perstudenti.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  hasError={error.includes("email")}
                />
            </FieldWrapper>
            <BreakLine />
            <FieldWrapper>
              <FieldIcon src={LockIcon} alt="Lock Icon" />
                <FieldText
                  type={showPassword ? "text" : "password"}
                  placeholder="Inserisci la tua password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  hasError={error.includes("password")}
                />
              <TogglePasswordButton onClick={() => setShowPassword(!showPassword)}>
                <img src={showPassword ? EyeOffIcon : EyeIcon} alt="Toggle Password Visibility" />
              </TogglePasswordButton>
            </FieldWrapper>

          </Form>
          <Conditions>Informativa Privacy</Conditions>
        </FormWrapper>

        {error && <ErrorModal message={error} onClose={() => setError("")} />}

          <LoginButton type="button" onClick={handleLogin} disabled={loading || lockout}>
              {loading ? (
                  <Spinner style={{ borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 0.1)', borderLeftColor: '#fff' }} />
              ) : (
                  <TextButton>Accedi</TextButton>
              )}
          </LoginButton>
        <WrapperRegistrati>
          <BreakLineL />
          <Text onClick={() => navigate("/registrati", { state: { backgroundLocation } })}>
            o Registrati
          </Text>
          <BreakLineR />
        </WrapperRegistrati>
      </LoginFormContainer>
    </LoginFormWrapper>
    </>
  );
};

export default LoginForm;
