import React from "react";
import styled from "styled-components";

// Styled Components
const AdInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.background.default};
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const HostInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HostAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ccc; /* Placeholder */
`;

const HostName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.span`
  font-size: 18px;
`;

const Description = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
`;

const AdInfo = ({ title, venditore = {}, details = [], description }) => {
    return (
        <AdInfoWrapper>


            {/* Informazioni sull'host 
            <HostInfo>
                <HostAvatar src={venditore.avatar || "https://via.placeholder.com/48"} alt={venditore.username} />
                <HostName>{venditore.username || "Venditore sconosciuto"} - {venditore.is_privato ? "Privato" : "Azienda"}</HostName>
            </HostInfo>*/}

            {/* Dettagli */}
            <DetailsList>
                {/* Titolo */}
                <Title>{title}</Title>
                {details.map((detail, index) => (
                    <DetailItem key={index}>
                        <IconWrapper>{detail.icon}</IconWrapper>
                        <span>{detail.text}</span>
                    </DetailItem>
                ))}
            </DetailsList>

            {/* Descrizione */}
            <Description>{description}</Description>
        </AdInfoWrapper>
    );
};

export default AdInfo;
