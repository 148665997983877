import React, { useState } from "react";
import styled from "styled-components";
import axiosInstance from "../../api/axiosConfig"; // Istanza Axios
import UserIcon from "../../assets/icons/user.svg";
import LockIcon from "../../assets/icons/lockIcon.svg";
import EmailIcon from "../../assets/icons/Email.svg"; // Nuova icona per email

// Styled Components
const RegisterFormMobileWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px; /* Spazio per la NavbarMobileBottom */
  background-color: ${(props) => props.theme.palette.background.default};
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RegisterFormContainer = styled.div`
  width: 90%;
  max-width: 360px;
  padding: 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 16px;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  margin: 8px 0;
`;

const HeaderForm = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
  text-align: left;
`;

const FormWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Form = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FieldIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const FieldText = styled.input`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  border: none;
  outline: none;
  flex: 1;

  &::placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

const CheckboxLabel = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 12px;
  font-weight: 400;
`;

const RegisterButton = styled.button`
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) =>
    props.disabled ? props.theme.palette.divider : props.theme.palette.primary.main};
  color: ${(props) => (props.disabled ? "#aaa" : "#fff")};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin-top: 16px;
`;

const TextButton = styled.div`
  color: ${(props) => (props.disabled ? "#aaa" : "#fff")};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 8px;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 14px;
  margin-top: 8px;
`;

const RegisterFormMobile = ({ onBack }) => {
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const isFormValid =
    formData.email &&
    formData.username &&
    formData.password &&
    formData.confirmPassword === formData.password &&
    formData.termsAccepted;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      let newUsername = prevData.username;

      if (name === "email") {
        const emailPrefix = value; // Prende solo la parte prima di @
        if (!prevData.username || prevData.username === prevData.email) {
          newUsername = emailPrefix;
        }
      }

      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
        username: newUsername,
      };
    });
  };

  const handleRegister = async () => {
    if (!isFormValid) {
      setErrorMessage("Compila tutti i campi correttamente.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await axiosInstance.post("/registrazione/", {
        email: formData.email,
        username: formData.username,
        password: formData.password,
      });

      if (response.data.status === "success") {
        setSuccessMessage(
          response.data.message || "Registrazione avvenuta con successo! Controlla la tua email."
        );

        setTimeout(() => onBack(), 3000);
      } else {
        setErrorMessage(response.data.message || "Errore durante la registrazione.");
      }
    } catch (error) {
      console.error("❌ Errore nella registrazione:", error);
      setErrorMessage(error.response?.data?.message || "Errore imprevisto. Riprova più tardi.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegisterFormMobileWrapper>
      <RegisterFormContainer>
        <Title>Registrati su Perstudenti.com</Title>

        <BreakLine />

        <HeaderForm>Unisciti alla nostra community</HeaderForm>

        <FormWrapper>
          <Form>
            <FieldWrapper>
              <FieldIcon src={EmailIcon} alt="Email Icon" />
              <FieldText type="email" name="email" placeholder="Inserisci la tua email" value={formData.email} onChange={handleChange} />
            </FieldWrapper>

            <BreakLine />

            <FieldWrapper>
              <FieldIcon src={UserIcon} alt="User Icon" />
              <FieldText type="text" name="username" placeholder="Inserisci il tuo username" value={formData.username} onChange={handleChange} />
            </FieldWrapper>

            <BreakLine />

            <FieldWrapper>
              <FieldIcon src={LockIcon} alt="Password Icon" />
              <FieldText type="password" name="password" placeholder="Inserisci la tua password" value={formData.password} onChange={handleChange} />
            </FieldWrapper>

            <BreakLine />

            <FieldWrapper>
              <FieldIcon src={LockIcon} alt="Confirm Password Icon" />
              <FieldText type="password" name="confirmPassword" placeholder="Conferma la tua password" value={formData.confirmPassword} onChange={handleChange} />
            </FieldWrapper>
          </Form>

          <CheckboxWrapper>
            <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} />
            <CheckboxLabel>Accetto i Termini e le Condizioni</CheckboxLabel>
          </CheckboxWrapper>
        </FormWrapper>

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

        <RegisterButton disabled={!isFormValid || loading} onClick={handleRegister}>
          {loading ? "Registrazione..." : "Registrati"}
        </RegisterButton>
      </RegisterFormContainer>
    </RegisterFormMobileWrapper>
  );
};

export default RegisterFormMobile;
