import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import axiosInstance from "../api/axiosConfig"; // 🔹 Istanza Axios
import CloseIcon from "../assets/icons/closeicon.svg";
import UserIcon from "../assets/icons/user.svg";
import LockIcon from "../assets/icons/lockIcon.svg";
import EmailIcon from "../assets/icons/Email.svg"; // Nuova icona per email
import LoginForm from "./LoginForm";

// Styled Components
const RegisterFormWrapper = styled.div`
  width: 694px;
  height: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const RegisterFormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CloseButton = styled.div`
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 20px;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  margin: 8px 0;
`;

const HeaderForm = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: 340px;
`;

const Form = styled.div`
  width: 100%;
  height: 290px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.divider};
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FieldIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const FieldText = styled.input`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: "PP Gosha Sans";
  font-size: 24px;
  font-weight: 700;
  border: none;
  outline: none;
  flex: 1;

  &::placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Checkbox = styled.input`
  width: 16px;
  height: 16px;
`;

const ConditionsText = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 400;
`;

const RegisterButton = styled.button`
  display: flex;
  width: 100%;
  padding: 11px 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) =>
    props.disabled ? props.theme.palette.divider : props.theme.palette.primary.main};
  color: #fff;
  font-family: "PP Gosha Sans";
  font-size: 24px;
  font-weight: 700;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin-top: 16px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

// 🔹 Nuovo Styled Component per i messaggi di errore
const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 8px;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 14px;
  margin-top: 8px;
`;

const WrapperLogin = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 32px;
  margin-top: 24px;
  width: 100%;
`;

const BreakLineL = styled.div`
  width: 209px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const BreakLineR = styled.div`
  width: 209px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;


const Text = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 400;
  cursor: pointer; /* 🔹 Cambia il cursore in hand cliccabile */
  text-align: center; /* Centra il testo */
  margin-top: 16px; /* Spazio sopra il testo */
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main}; /* Colore in hover (opzionale) */
  }
`;


const RegisterForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  const isFormValid =
    formData.email &&
    formData.username &&
    formData.password &&
    formData.confirmPassword === formData.password &&
    formData.termsAccepted;
  
  const navigate = useNavigate();
  const location = useLocation();

  // Prendi lo sfondo originale o fallback a "/"
  const backgroundLocation = location.state?.backgroundLocation || { pathname: "/" };



const handleChange = (e) => {
  const { name, value, type, checked } = e.target;

  setFormData((prevData) => {
    let newUsername = prevData.username;

    if (name === "email") {
      // Estrai il prefisso email prima di @
      const emailPrefix = value; 
      if (!prevData.username || prevData.username === prevData.email) {
        newUsername = emailPrefix;
      }
    }

    return {
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
      username: newUsername, // Aggiorna lo username
    };
  });
};

const handleRegister = async () => {
  if (!isFormValid) {
    setErrorMessage("Compila tutti i campi correttamente.");
    return;
  }

  setLoading(true);
  setErrorMessage("");
  setSuccessMessage("");

  try {
    const response = await axiosInstance.post("/registrazione/", {
      email: formData.email,
      username: formData.username,
      password: formData.password,
    });

    if (response.data.status === "success") {
      setSuccessMessage(String(response.data.message || "Registrazione avvenuta con successo! Controlla la tua email."));
      
      // Chiudi il form dopo 3 secondi
      setTimeout(() => onClose(), 3000);
    } else {
      setErrorMessage(String(response.data.message || "Errore durante la registrazione."));
    }
  } catch (error) {
    console.error("❌ Errore nella registrazione:", error);
    setErrorMessage(String(error.response?.data?.message || "Errore imprevisto. Riprova più tardi."));
  } finally {
    setLoading(false);
  }
};

const handleClose = () => {
  window.location.href = "/";
};




  return (
    <RegisterFormWrapper>
      <RegisterFormContainer>
        {/* Pulsante di chiusura */}
        <CloseButton onClick={handleClose}>
          <img src={CloseIcon} alt="Close" />
        </CloseButton>

        {/* Titolo centrato */}
        <Title>Registrati su Perstudenti.com</Title>

        {/* Linea divisoria */}
        <BreakLine />

        {/* Header Form */}
        <HeaderForm>Unisciti alla nostra community</HeaderForm>

        {/* Form */}
        <FormWrapper>
          <Form>
            <FieldWrapper>
              <FieldIcon src={EmailIcon} alt="Email Icon" />
              <FieldText
                type="email"
                name="email"
                placeholder="Inserisci la tua email"
                value={formData.email}
                onChange={handleChange}
              />
            </FieldWrapper>

            <BreakLine />

            <FieldWrapper>
              <FieldIcon src={UserIcon} alt="User Icon" />
              <FieldText
                type="text"
                name="username"
                placeholder="Inserisci il tuo username"
                value={formData.username}
                onChange={handleChange}
              />
            </FieldWrapper>

            <BreakLine />

            <FieldWrapper>
              <FieldIcon src={LockIcon} alt="Password Icon" />
              <FieldText
                type="password"
                name="password"
                placeholder="Inserisci la tua password"
                value={formData.password}
                onChange={handleChange}
              />
            </FieldWrapper>

            <BreakLine />

            <FieldWrapper>
              <FieldIcon src={LockIcon} alt="Confirm Password Icon" />
              <FieldText
                type="password"
                name="confirmPassword"
                placeholder="Conferma la tua password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </FieldWrapper>
          </Form>

          <CheckboxWrapper>
            <Checkbox type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} />
            <ConditionsText>Accetto i Termini e le Condizioni</ConditionsText>
          </CheckboxWrapper>
        </FormWrapper>

        

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

        <RegisterButton disabled={!isFormValid || loading} onClick={handleRegister}>
          {loading ? "Registrazione..." : "Registrati"}
        </RegisterButton>
        <WrapperLogin>
          <BreakLineL />
          <Text onClick={() => navigate("/login", { state: { backgroundLocation} })}>
            Sei già registrato? Accedi
          </Text>
          <BreakLineR />
        </WrapperLogin>
      </RegisterFormContainer>
    </RegisterFormWrapper>
  );
};

export default RegisterForm;
