import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import CardProduct from "./Ad/CardProduct";
import axiosPublic from "../api/axiosConfigPub";

// Styled Components

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

// Icona di caricamento (puoi sostituirla con un'immagine se preferisci)
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const AnnunciWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  padding: 16px;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Loader = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
`;

const BackButton = styled.button`
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
  background: transparent;
  border: none;
  cursor: pointer;
`;

const ElencoAnnunci = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [annunci, setAnnunci] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAnnuncio, setSelectedAnnuncio] = useState(null); // ✅ Stato per l'annuncio selezionato

  const Loader = () => (
  <LoaderOverlay>
    <Spinner />
  </LoaderOverlay>
);

  // ✅ Recupera `searchQuery` dall'URL
useEffect(() => {
  const searchParams = new URLSearchParams(location.search); // 🔵 Migliorato (usa location.search direttamente)
  setSearchQuery(searchParams.get("q") || "");
}, [location.search]);


  // ✅ Chiamata API per caricare gli annunci
const fetchAnnunci = useCallback(async () => {
  setLoading(true);
  setError(null);
  try {
    const response = await axiosPublic.get("/annunci/", {
      params: searchQuery.trim() ? { q: searchQuery.trim() } : {}
    });

    setAnnunci(Array.isArray(response.data) ? response.data : []); // 🔵 Migliore gestione degli annunci
  } catch (error) {
    console.error("❌ Errore nel recupero degli annunci:", error);
    setError(error.response
      ? `Errore ${error.response.status}: ${error.response.data?.message || "Impossibile caricare gli annunci."}`
      : "Errore di connessione. Riprova più tardi."
    );
  } finally {
    setLoading(false);
  }
}, [searchQuery]); // 🔵 Ottimizzato con `useCallback`

useEffect(() => {
  fetchAnnunci();
}, [fetchAnnunci]); // 🔵 Usiamo `fetchAnnunci` invece di dichiararlo inline





// ✅ Loader durante caricamento
if (loading) return <Loader />; // 🔵 Usiamo un componente separato per il Loader


// ✅ Errore di caricamento
if (error) {
  return <p>{error}</p>;
}

// ✅ Nessun risultato trovato
if (annunci.length === 0) {
  return (
    <div style={{ textAlign: "center", padding: "20px", color: "#888" }}>
      {searchQuery
        ? `Nessun annuncio trovato per "${searchQuery}".`
        : "Nessun annuncio disponibile al momento."}
    </div>
  );
}



  // ✅ Render normale degli annunci SOLO dopo il caricamento
  return (
    <>

    <AnnunciWrapper>
      {annunci.map((annuncio) => (
        <CardProduct
          key={annuncio.id}
          id={annuncio.id}
          images={annuncio.images_urls}
          title={annuncio.titolo}
          seller={annuncio.user_username}
          state={annuncio.condizioni}
          price={annuncio.prezzo}
          sponsored={annuncio.is_featured}
          onClick={() => {navigate(`/annuncio/${annuncio.id}`); // ✅ Su desktop, naviga alla pagina annuncio
            
          }}
        />
      ))}
    </AnnunciWrapper>
      </>
  );
};

export default ElencoAnnunci;
