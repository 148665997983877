import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axiosInstance from "../../../api/axiosConfig";
import CardProduct from "../CardProduct"; // ✅ Componente Card

// Styled Components
const RelatedsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
  width: 100%;
`;

const SectionTitle = styled.h2`
  font-family: "PP Gosha Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 8px;
`;

const Grid = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto; /* Abilita lo swipe orizzontale */
  scroll-behavior: smooth;
  scrollbar-width: none; /* Nasconde la scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none; /* Nasconde la scrollbar in Chrome e Safari */
  }
`;

const CardWrapper = styled.div`
  flex-shrink: 0;
  scroll-snap-align: start;
`;

const AdMobileRelateds = ({ annuncioId }) => {
  const [annunciCorrelati, setAnnunciCorrelati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!annuncioId) return;

    const fetchAnnunciCorrelati = async () => {
      try {
        const response = await axiosInstance.get(`/annuncio/correlati/${annuncioId}/`);
        console.log("✅ Annunci correlati (Mobile):", response.data);
        setAnnunciCorrelati(response.data);
      } catch (error) {
        console.error("❌ Errore nel recupero degli annunci correlati (Mobile):", error);
        setError("Impossibile caricare gli annunci correlati.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnnunciCorrelati();
  }, [annuncioId]);

  if (loading) return <p>Caricamento annunci correlati...</p>;
  if (error) return <p>{error}</p>;

  return (
    <RelatedsWrapper>
      <SectionTitle>Annunci Suggeriti</SectionTitle>
      <Grid>
        {annunciCorrelati.map((annuncio) => (
          <CardWrapper key={annuncio.id}>
            <CardProduct
              id={annuncio.id}
              images={annuncio.images_urls}
              title={annuncio.titolo}
              seller={annuncio.user_username}
              state={annuncio.condizioni}
              price={annuncio.prezzo}
              sponsored={annuncio.is_featured}
              onClick={() => (window.location.href = `/annuncio/${annuncio.id}`)}
            />
          </CardWrapper>
        ))}
      </Grid>
    </RelatedsWrapper>
  );
};

export default AdMobileRelateds;
