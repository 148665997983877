import React, { createContext, useContext, useState, useEffect } from "react";
import axiosInstance, {getCookie, logoutUser} from "../api/axiosConfig";

const UserContext = createContext();

export const UserProvider = ({ children }) => {

  
const [user, setUser] = useState(() => {
  const storedUser = localStorage.getItem("userInfo");
  return storedUser
    ? { ...JSON.parse(storedUser), isLoggedIn: true }
    : {
        userId: null,
        username: "",
        email: "",
        avatar: "",
        hasCompletedOnboarding: false,
        isLoggedIn: false,
      };
});


  // ✅ Funzione per salvare i dati utente nel context e nel localStorage

  const saveUserData = ({ id, username, email }) => {
    const userData = {
      userId: id,
      username,
      email,
      isLoggedIn: true,
    };
    setUser(userData);
    localStorage.setItem("userInfo", JSON.stringify(userData));  // ✅ salva localmente
  };

  const [loading, setLoading] = useState(true);  // 🔄 Aggiungi lo stato di loading

    // ✅ Recupera i dati utente all'avvio
  useEffect(() => {
    console.log("📥 Tentativo di recupero dati utente...");

  const fetchUserData = async () => {
    try {
      // 🔄 Tenta il refresh prima di chiamare `/user_info/`
      console.log("🔄 Tentativo di refresh del token...");
      await axiosInstance.post("/refresh_token/", null, { withCredentials: true });
      console.log("✅ Refresh token riuscito. Recupero dati utente...");

      // 🔄 Ora possiamo chiamare `/user_info/`
      const response = await axiosInstance.get("/user_info/", {
        withCredentials: true  // ✅ Necessario per inviare i cookie HTTP-only
      });

      console.log("✅ Dati utente ricevuti:", response.data);

      setUser({
        userId: response.data.id,
        username: response.data.username,
        avatar: response.data.avatar || "",
        isLoggedIn: true,
        hasCompletedOnboarding: response.data.hasCompletedOnboarding || false
      });
      console.log("✅ Utente impostato come loggato.");

    } catch (error) {
      console.error("❌ Errore nel recupero dei dati utente:", error);
      console.log("❌ Response completa:", error.response?.data);

      // 🔴 Se il refresh fallisce, l'utente non è loggato
      if (error.response?.status === 401) {
        console.log("❌ Refresh token scaduto o non valido. Nessun utente loggato.");
        setUser((prev) => ({ ...prev, isLoggedIn: false }));
      }
    } finally {
      setLoading(false);
    }
  };

  fetchUserData();
}, [setUser]);


  // ✅ Funzione per il login
  const handleLoginSuccess = (userId, username, email) => {
    console.log("🔍 Login Success:");
    console.log("➡️ userId:", userId);
    console.log("➡️ username:", username);
    console.log("➡️ email:", email);
    saveUserData({ id: userId, username, email });
  };

  // ✅ Funzione per il logout
const handleLogout = async (redirect = true) => {
  try {
    console.log("➡️ Logout tramite UserContext...");

    await axiosInstance.post("/logout/", null, {
      withCredentials: true,
    });

    // Pulisce stato e storage
    setUser({
      userId: null,
      username: "",
      email: "",
      avatar: "",
      hasCompletedOnboarding: false,
      isLoggedIn: false,
    });

    localStorage.removeItem("userInfo");

    console.log("✅ Logout effettuato.");

    if (redirect) {
      window.location.href = "/login";
    }

  } catch (error) {
    console.error("❌ Errore durante il logout:", error);
  }
};


  return (
    <UserContext.Provider value={{ user, setUser, loading, handleLoginSuccess, handleLogout, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
