import React from "react";
import styled from "styled-components";
import SellerImage from "../../assets/icons/user.svg"; // Immagine di default

// Styled Components
const SellerBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

const SellerCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-radius: 12px;
`;

const SellerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SellerAvatarWrapper = styled.div`
  position: relative;
`;

const SellerAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const SellerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SellerName = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
`;

const SellerRole = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.secondary};
`;

// 🔹 Wrapper per le recensioni
const SellerReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`;

// 🔹 Wrapper per le valutazioni con icona
const SellerFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`;

// 🔹 Componente Icon per il voto con SVG
const Icon = styled.svg`
  width: 16px;
  height: 16px;
  fill: ${(props) => props.theme.palette.primary.main};
`;

const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "PP Gosha Sans";
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) => props.theme.palette.text.secondary};
  }
`;

const SellerBadge = ({ venditore }) => {
  if (!venditore) {
    return null;
  }

  return (
    <SellerBadgeWrapper>
      {/* Titolo */}
      <Title>Conosci meglio il venditore</Title>

      {/* Card del venditore */}
      <SellerCard>
        <SellerInfo>
          {/* Avatar del venditore */}
          <SellerAvatarWrapper>
            <SellerAvatar src={venditore.avatar || SellerImage} alt={venditore.nomeCompleto} />
          </SellerAvatarWrapper>

          {/* Dettagli del venditore */}
          <SellerDetails>
            <SellerName>{venditore.nomeCompleto || "Venditore"}</SellerName>
            <SellerRole>{venditore.is_azienda ? "Azienda" : "Privato"}</SellerRole>
          </SellerDetails>
        </SellerInfo>

        {/* Sezione Valutazioni e Recensioni */}
        <div>
          <SellerReviewWrapper>
            <div>{venditore.numero_di_recensioni || 0}</div>
            <div className="subtext">recensioni</div>
          </SellerReviewWrapper>

          <SellerFeedbackWrapper>
            <div>
              {venditore.valutazioni ? (
                <>
                  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12 2L14.6142 8.09619L21.364 8.95492L16.682 13.1078L18.2284 19.7951L12 16.5738L5.77163 19.7951L7.31801 13.1078L2.63599 8.95492L9.38583 8.09619L12 2Z" />
                  </Icon>{" "}
                  {venditore.valutazioni} ★
                </>
              ) : (
                "Nessuna valutazione"
              )}
            </div>
            <div className="subtext">valutazione</div>
          </SellerFeedbackWrapper>
        </div>
      </SellerCard>

      {/* Account info */}
      <AccountInfo>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path d="M12 2L14.6142 8.09619L21.364 8.95492L16.682 13.1078L18.2284 19.7951L12 16.5738L5.77163 19.7951L7.31801 13.1078L2.63599 8.95492L9.38583 8.09619L12 2Z" fill="black" />
        </svg>
        {venditore.is_azienda ? "Account aziendale" : "Account privato"}
      </AccountInfo>
    </SellerBadgeWrapper>
  );
};

export default SellerBadge;
