// stepsConfig.js

export const STEPS_CONFIG = {
  creazione: [
    "CreaAnnuncio",
    "StepCategoria",
    "StepDatiAnnuncio",
    "StepImages",
    "StepAdSponsored",
    "StepRiepilogo",    
  ],

  draft: [
    "StepDatiAnnuncio",
    "StepImages",
    "StepAdSponsored",
    "StepRiepilogo",
    
  ],
  modifica: [
    "StepDatiAnnuncio",
    "StepImages",
    "StepAdSponsored",
    "StepRiepilogo",
  ],
  rifiutato:[
    "StepCategoria",
    "StepDatiAnnuncio",
    "StepImages",
    "StepAdSponsored",
    "StepRiepilogo",
  ],  
};

export const STEP_COMPLETION_STATUS = {
  CreaAnnuncio: false,
  StepCategoria: false,
  StepDatiAnnuncio: false,
  StepImages: false,
  StepRiepilogo: false,
  StepAdSponsored: false,
};


export const LABELS_CONFIG = {
  StepCategoria: {
    default: "Seleziona Categoria",
    active: {
      default: "Seleziona Categoria", // Questa è la schermata introduttiva dello StepCategoria
    },
  },

  StepCategoriaActive: {
    default: "Avanti",
    active: {
      default: "Avanti",
    },
  },
  StepDatiAnnuncio: {
    default: "Inserisci Titolo",
    active: {
      0: "Inserisci descrizione",
      1: "Inserisci condizioni",
      2: "Avanti",
      3: "Avanti",
      4: "Avanti",
      5: "Inserisci prezzo",
      6: "Carica immagini",
    },
  },
  StepImages: {
    default: "Carica Immagini",
    active: "Riepilogo Annuncio",
  },
  StepRiepilogo: {
    default: "Carica Annuncio",
  },
  StepAdSponsored: {
    default: "Avanti",
    secondary: "Salta",
    tertiary: "Indietro",
  },
};

// 🔹 Nuova configurazione step interni:
export const STEP_DATI_ANNUNCIO_ACTIVE_CONTENT = {
  0: {
    title: "Titolo dell’annuncio",
    subtitle: "(categoria: {categoria})",
    description: "Un buon titolo, breve ed efficace è il primo passo per far trovare facilmente il tuo oggetto.",
    inputType: "titolo",
    placeholder: "Digita qui il tuo titolo",
    maxLength: 100,
  },
  1: {
    title: "Scrivi la tua descrizione",
    subtitle: "Descrivi al meglio il tuo oggetto",
    description: "Non dimenticare i particolari.",
    inputType: "descrizione",
    placeholder: "Digita qui la descrizione",
    maxLength: 600,
    multiline: true,
  },
  2: {
    title: "Seleziona le condizioni dell'oggetto",
    subtitle: "Scegli lo stato del tuo prodotto",
    inputType: "condizioni",
  },
  3: {
    title: "L'oggetto ha spedizione digitale?",
    inputType: "spedizione_digitale",
  },
  4: {
    title: "L'oggetto può essere restituito?",
    inputType: "reso_oggetto",
  },
  5: {
    title: "Aggiungi parole chiave",
    subtitle: "Aiuta le persone a trovare il tuo annuncio più facilmente",
    inputType: "keyword",
    placeholder: "Scrivi una parola chiave e premi Invio",
  },
  6: {
    title: "Ora imposta il prezzo",
    subtitle: "Puoi modificarlo in qualsiasi momento.",
    inputType: "prezzo",
  },
};


export const STEP_IMAGES_ACTIVE_CONTENT = {
  0: {
    title: "Carica l'immagine principale",
    description: "L'immagine principale sarà la prima che vedranno gli utenti.",
    uploadLabel: "Aggiungi immagine principale",
    multiple: false,
    maxImages: 1,
  },
  1: {
    title: "Carica altre immagini",
    description: "Puoi aggiungere fino a 4 immagini aggiuntive.",
    uploadLabel: "Aggiungi altre immagini",
    multiple: true,
    maxImages: 4,
  },
};