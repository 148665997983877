import React from "react";
import styled from "styled-components";

// Styled Components
const FooterWrapper = styled.footer`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;

const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;

  h4 {
    margin-bottom: 12px;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.palette.text.primary};
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;
      font-size: 14px;
      color: ${(props) => props.theme.palette.text.secondary};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 16px;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const Footer = () => {
    return (
        <FooterWrapper>
            {/* Parte superiore del footer */}
            <FooterTop>
                <FooterColumn>
                    <h4>Assistenza</h4>
                    <ul>
                        <li>Centro Assistenza</li>
                        <li>AirCover</li>
                        <li>Antidiscriminazione</li>
                        <li>Supporto alla disabilità</li>
                        <li>Opzioni di cancellazione</li>
                        <li>Segnala problema nel quartiere</li>
                    </ul>
                </FooterColumn>
                <FooterColumn>
                    <h4>Ospitare</h4>
                    <ul>
                        <li>Apri un Airbnb</li>
                        <li>AirCover per gli host</li>
                        <li>Risorse per gli host</li>
                        <li>Forum della community</li>
                        <li>Ospitare responsabilmente</li>
                        <li>Partecipa a una lezione gratuita sull'ospitalità</li>
                        <li>Trova un co-host</li>
                    </ul>
                </FooterColumn>
                <FooterColumn>
                    <h4>Airbnb</h4>
                    <ul>
                        <li>Newsroom</li>
                        <li>Nuove funzionalità</li>
                        <li>Opportunità di lavoro</li>
                        <li>Investitori</li>
                        <li>Gift card</li>
                        <li>Soggiorni con Airbnb.org</li>
                    </ul>
                </FooterColumn>
            </FooterTop>

            {/* Parte inferiore del footer */}
            <FooterBottom>
                <div>
                    © 2025 Perstudenti, Inc. · Privacy · Termini · Mappa del sito · Dettagli
                    dell'azienda
                </div>
                <div>
                    <span>Italiano (IT)</span> · <span>€ EUR</span>
                </div>
                <SocialIcons>
                    <img src="/assets/icons/facebook.svg" alt="Facebook" />
                    <img src="/assets/icons/twitter.svg" alt="Twitter" />
                    <img src="/assets/icons/instagram.svg" alt="Instagram" />
                </SocialIcons>
            </FooterBottom>
        </FooterWrapper>
    );
};

export default Footer;
