import React from "react";
import styled from "styled-components";

const AdNavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 80px;
  height: 60px;
`;

const NavSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const NavItem = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const PriceButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TitleText = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  font-family: "PP Gosha Sans";
  font-size: 14px;
  font-weight: 700;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const AdNavbar = ({ title, price, showButton, imgSectionRef, infoSectionRef }) => {
  return (
    <AdNavbarWrapper>
      {/* Sezione a sinistra */}
      <NavSection>
        <NavItem onClick={() => imgSectionRef.current?.scrollIntoView({ behavior: "smooth" })}>
          Foto
        </NavItem>
        <NavItem onClick={() => infoSectionRef.current?.scrollIntoView({ behavior: "smooth" })}>
          Descrizione
        </NavItem>
      </NavSection>

      {/* Sezione a destra */}
      {showButton && (
        <PriceButtonWrapper>
          <TitleText>{title || "Titolo non disponibile"}</TitleText>
          {price && <Price>{price} €</Price>}
          <Button>Contatta il Venditore</Button>
        </PriceButtonWrapper>
      )}
    </AdNavbarWrapper>
  );
};

export default AdNavbar;
