import React from "react";
import styled from "styled-components";
import AdDetails from "../Ad/AdDetails"; // Import del componente AdDetails

// Styled Components
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const Price = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

const AdInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AdDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AdTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #000;
`;

const AdCategory = styled.div`
  font-size: 14px;
  color: #666;
`;

const AdImage = styled.div`
  width: 80px;
  height: 80px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 12px;
  color: #555;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const DetailsContainer = styled.div`
  margin-top: 12px;
`;

const CardAdMessage = ({ annuncio }) => {
  return (
    <CardWrapper>
      <Price>{annuncio.prezzo ? `${annuncio.prezzo} €` : "00,00 €"}</Price>

      <AdInfoWrapper>
        <AdDetailsWrapper>
          <AdTitle>{annuncio.titolo || "Titolo Annuncio"}</AdTitle>
          <AdCategory>{annuncio.categoria || "Categoria Annuncio"}</AdCategory>
        </AdDetailsWrapper>
        <AdImage>
          {annuncio.img_main_url ? (
            <img src={annuncio.img_main_url} alt={annuncio.titolo} />
          ) : (
            "IMG Main Annuncio"
          )}
        </AdImage>
      </AdInfoWrapper>

      {/* Componente AdDetails */}
      <DetailsContainer>
        <AdDetails
          condizioni={annuncio.condizioni}
          spedizione={annuncio.spedizione_digitale ? "Sì" : "No"}
          reso={annuncio.reso_oggetto ? "Sì" : "No"}
        />
      </DetailsContainer>
    </CardWrapper>
  );
};

export default CardAdMessage;
