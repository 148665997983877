import React from "react";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Styled Components
const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
`;

const Title = styled.h2`
  font-family: "PP Gosha Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  margin-bottom: 8px;
`;

const LocationText = styled.p`
  font-family: "PP Gosha Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  margin-bottom: 16px;
`;

const ShowMoreButton = styled.a`
  margin-top: 16px;
  background: none;
  border: none;
  color: #000;
  font-family: "PP Gosha Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration-thickness: 2px;
  }
`;

const AdMap = ({ latitudine, longitudine, citta }) => {
  if (!latitudine || !longitudine) {
    return (
      <MapWrapper>
        <Title>Dove si trova questo annuncio</Title>
        <LocationText>Località non disponibile</LocationText>
      </MapWrapper>
    );
  }

  return (
    <MapWrapper>
      <Title>Dove si trova l'annuncio</Title>
      <LocationText>{citta || "Località non specificata"}</LocationText>

      {/* Mappa OpenStreetMap con Leaflet */}
      <MapContainer center={[latitudine, longitudine]} zoom={13} style={{ height: "400px", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[latitudine, longitudine]}>
          <Popup>{citta || "Località"}</Popup>
        </Marker>
      </MapContainer>

      {/* Link a OpenStreetMap */}
      <ShowMoreButton
        href={`https://www.openstreetmap.org/?mlat=${latitudine}&mlon=${longitudine}#map=15/${latitudine}/${longitudine}`}
        target="_blank"
      >
        Mostra su OpenStreetMap
      </ShowMoreButton>
    </MapWrapper>
  );
};

export default AdMap;
