import React from "react";
import styled from "styled-components";

// Wrapper fisso a fondo pagina
const FixedFormWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 12px 16px;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  z-index: 2000;
`;

// Layout interno a due colonne
const ContactFormMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;  // layout orizzontale a due colonne
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
`;

// Wrapper Titolo e Prezzo (colonna sinistra)
const TitlePriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  flex: 1;
  padding-right: 12px;
`;

const TitleText = styled.h3`
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin: 0;
`;

const PriceWrapper = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-top: 4px;
`;

// Pulsante principale (colonna destra)
const ButtonPrimary = styled.button`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  font-family: "PP Gosha Sans";
  font-size: 14px;
  font-weight: 700;
  padding: 12px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const ContactFormMobile = ({ buttonRef, price, title }) => {
  return (
    <FixedFormWrapper>
      <ContactFormMobileWrapper>
        {/* Colonna Sinistra: Dati Annuncio */}
        <TitlePriceWrapper>
          <TitleText>{title || "Titolo non disponibile"}</TitleText>
          <PriceWrapper>{price ? `${price} €` : "Prezzo non disponibile"}</PriceWrapper>
        </TitlePriceWrapper>

        {/* Colonna Destra: Pulsante di Contatto */}
        <ButtonPrimary ref={buttonRef}>
          Contatta il Venditore
        </ButtonPrimary>
      </ContactFormMobileWrapper>
    </FixedFormWrapper>
  );
};

export default ContactFormMobile;
