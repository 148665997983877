import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 14px;
`;

const ImgContainer = styled.div`
  width: 60px;
  height: 60px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const InserisciAnnuncioCard = () => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate("/crea-annuncio")}>
      <Text>
        <strong>Inserisci Annuncio</strong><br />
        È facile iniziare a vendere e guadagnare qualcosa in più
      </Text>
      <ImgContainer>
        Img
      </ImgContainer>
    </Card>
  );
};

export default InserisciAnnuncioCard;
