import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SearchIcon from "../../assets/icons/search.svg";
import FilterIcon from "../../assets/icons/filter.svg"; // Icona dei filtri

// Styled Components
const NavbarMobileWrapper = styled.div`
  display: inline-flex;
  padding: 12px 18px 10px 19px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.default};
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; /* Assicura che sia visibile sopra altri elementi */
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SearchbarWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid #d9d9d9;
  border-radius: 32px;
  box-shadow: ${(props) =>
    props.isFocused
      ? "0px 10px 20px rgba(0, 0, 0, 0.25)"
      : "0px 2px 11px 2px rgba(0, 0, 0, 0.15)"};
  height: 64px;
  width: 270px;
  padding: 0 20px;
  justify-content: flex-start; /* Allinea gli elementi a sinistra */
  transform: ${(props) => (props.isFocused ? "scale(1.05)" : "scale(1)")};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

const IconWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 40px;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin-right: 12px; /* Spazio tra icona e input */

  img {
    width: 20px;
    height: 20px;
  }
`;

const InputField = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.primary};
  width: 100%;

  &::placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: 14px;
    font-weight: 400;
  }
`;

const FiltersWrapper = styled.button`
  display: flex;
  width: 36px;
  height: 36px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  background: ${(props) => props.theme.palette.background.default};
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
`;

const NavbarMobileTop = () => {
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (query.trim() !== "") {
      console.log("🔄 Sto cercando:", query.trim());

      const newUrl = `/annunci?q=${encodeURIComponent(query.trim())}`;
      console.log("🔄 Navigazione a URL:", newUrl);

      navigate(newUrl);

      setTimeout(() => {
        console.log("🧐 PRIMA della sostituzione, URL attuale:", window.location.href);
        window.history.pushState({}, "", newUrl);
        console.log("✅ URL aggiornato manualmente con pushState:", window.location.href);
      }, 300);
    }
  };

  return (
    <NavbarMobileWrapper>
      <SearchWrapper>
        <SearchbarWrapper isFocused={isFocused}>
          <IconWrapper onClick={handleSearch}>
            <img src={SearchIcon} alt="Search Icon" />
          </IconWrapper>
          <InputField
            type="text"
            placeholder={isFocused ? "" : "Cerca un annuncio..."}
            value={query}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
        </SearchbarWrapper>
{false && (
  <FiltersWrapper>
    <img src={FilterIcon} alt="Filter Icon" />
  </FiltersWrapper>
)}
      </SearchWrapper>
    </NavbarMobileWrapper>
  );
};

export default NavbarMobileTop;
