import React from "react";
import styled from "styled-components";
import useMediaQuery from "../hooks/useMediaQuery";
import NavbarTop from "./NavbarTop";
import NavbarBottom from "./NavbarBottom";
import NavbarMobileTop from "./Mobile/NavbarMobileTop";
import NavbarMobileBottom from "./Mobile/NavbarMobileBottom";
import { useUser } from "../context/UserContext";
import { useLocation } from "react-router-dom";
import { useAnnuncio } from "../context/AnnuncioContext";  // ✅ Importa il contesto degli annunci


const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.default};
  width: 100%;
  box-shadow: 0px 2px 11px 2px rgba(0, 0, 0, 0.1);
`;

const Navbar = ({ isMessaggiMode, setIsMessaggiMode }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { user } = useUser();
  const location = useLocation();
  const { annuncioTipo, annuncioStep, steps } = useAnnuncio();  // ✅ Recupera i dati dal contesto
  const isAnnuncioAttivo = annuncioTipo !== null && steps[annuncioStep] !== undefined;  // ✅ Verifica se siamo in modalità annuncio


  // Controlla se l'utente è sulla pagina dell'annuncio mobile
  const isAdMobilePage = /^\/annuncio\/\d+\/?$/.test(location.pathname);

  return (
    <NavbarWrapper isMessaggiMode={isMessaggiMode}>
      {isMobile ? (
        <>
          {!isAdMobilePage && !isMessaggiMode && <NavbarMobileTop />} {/* Nasconde in modalità Messaggi */}
          {!isAdMobilePage && <NavbarMobileBottom />} {/* Nasconde in modalità Messaggi */}
        </>
      ) : (
        <>
          <NavbarTop setIsMessaggiMode={setIsMessaggiMode} isMessaggiMode={isMessaggiMode} />
          {!isMessaggiMode && <NavbarBottom />} {/* Nasconde in modalità Messaggi */}
        </>
      )}
    </NavbarWrapper>
  );
};

export default Navbar;
