import React from "react";
import styled from "styled-components";
import ConditionIcon from "../../assets/icons/condition.svg";
import ShippingIcon from "../../assets/icons/shipping.svg";
import ReturnIcon from "../../assets/icons/return.svg";

// Styled Components
const AdDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.background.paper};
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`;

const DetailsContainer = styled.div`
  padding: 12px;
  max-width: ${(props) => (props.vertical ? "100%" : "90%")}; // 🔄 Aumenta la larghezza in modalità verticale
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")}; // 🔄 Passa a colonna se vertical
  gap: ${(props) => (props.vertical ? "12px" : "24px")}; // 🔄 Riduci il gap in modalità verticale
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;


  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")}; // 🔄 Cambia l'orientamento
  text-align: ${(props) => (props.vertical ? "center" : "left")}; // 🔄 Centra il testo se verticale
  gap: ${(props) => (props.vertical ? "8px" : "4px")}; // 🔄 Più spazio tra icona e testo in verticale
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: ${(props) => (props.vertical ? "0" : "8px")}; // 🔄 Rimuovi il margine a destra in verticale

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    color: ${(props) => props.theme.palette.text.secondary};
    font-family: "PP Gosha Sans";
    font-size: 14px;
    font-weight: 400;
  }

  .value {
    color: ${(props) => props.theme.palette.text.primary};
    font-family: "PP Gosha Sans";
    font-size: 16px;
    font-weight: 700;
  }
`;

// Component
const AdDetails = ({ condizioni = "Non specificato", spedizione, reso, vertical = false }) => {
  const details = [
    {
      icon: ConditionIcon,
      label: "Stato del prodotto",
      value: condizioni,
    },
    {
      icon: ShippingIcon,
      label: "Spedizione digitale",
      value: spedizione === true ? "Sì" : "No",  // 👈 Conversione sicura
    },
    {
      icon: ReturnIcon,
      label: "Reso dell'oggetto",
      value: reso === true ? "Sì" : "No",  // 👈 Conversione sicura
    },
  ];


  return (
    <AdDetailsWrapper>
      <Title>Dettagli del tuo annuncio</Title>
      <DetailsContainer vertical={vertical}>
        {details.map((detail, index) => (
          <DetailItem vertical={vertical} key={index}>
            <IconWrapper vertical={vertical}>
              <img src={detail.icon} alt={detail.label} />
            </IconWrapper>
            <DetailText>
              <div className="label">{detail.label}</div>
              <div className="value">{detail.value}</div>
            </DetailText>
          </DetailItem>
        ))}
      </DetailsContainer>
    </AdDetailsWrapper>
  );
};

export default AdDetails;
