import React from "react";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const LogoWrapper = styled.div`
  width: 200px;
  height: 90px;
  cursor: pointer;
`;

const stateMachineName = "StateMachine1";

const LogoAnimated = () => {
  const navigate = useNavigate();

  const { rive, RiveComponent } = useRive({
    src: process.env.PUBLIC_URL + "/logo.riv",
    stateMachines: stateMachineName,
    autoplay: true,
  });

  const hoverInTrigger = useStateMachineInput(rive, stateMachineName, "HoverIn");
  const hoverOutTrigger = useStateMachineInput(rive, stateMachineName, "HoverOut");

  const handleMouseEnter = () => {
    if (hoverInTrigger) hoverInTrigger.fire();
  };

  const handleMouseLeave = () => {
    if (hoverOutTrigger) hoverOutTrigger.fire();
  };

  const handleClick = () => {
    navigate("/"); // 👈 Vai alla home
  };

  return (
    <LogoWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <RiveComponent />
    </LogoWrapper>
  );
};

export default LogoAnimated;
