import React from "react";
import styled from "styled-components";

// Styled Components
const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  align-items: center;
  gap: 16px;
`;

const TitlePriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  text-align: left;
  width:100%;
`;

const TitleText = styled.h3`
  font-family: "PP Gosha Sans";
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin: 0;
  text-align: center;
`;

const PriceWrapper = styled.div`
  font-family: "PP Gosha Sans";
  font-size: 20px;
  font-weight: 700;
  color: #000;
`;

const ButtonPrimary = styled.button`
  width: 100%;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const ButtonSecondary = styled.button`
  width: 100%;
  background-color: transparent;
  color: ${(props) => props.theme.palette.primary.main};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  padding: 12px;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const ContactForm = ({ buttonRef, price, title, onContactClick }) => {
  return (
    <ContactFormWrapper>
      {/* Titolo e Prezzo dell'annuncio */}
      <TitlePriceWrapper>
        <TitleText>{title || "Titolo non disponibile"}</TitleText>
        <PriceWrapper>{price ? `${price} €` : "Prezzo non disponibile"}</PriceWrapper>
      </TitlePriceWrapper>

      {/* Pulsante per contattare il venditore */}
      <ButtonPrimary ref={buttonRef} onClick={onContactClick}>Contatta il Venditore</ButtonPrimary>

      {/* Pulsante per seguire l'annuncio */}
      <ButtonSecondary>Segui Annuncio</ButtonSecondary>
    </ContactFormWrapper>
  );
};

export default ContactForm;
