import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, Button, IconButton, CircularProgress, Slide, Dialog } from '@mui/material';
import { useAnnuncio } from '../../../context/AnnuncioContext';

import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import CardAdImages from '../../Ad/CardAdImages';
import AdDetails from '../../Ad/AdDetails';  // 🔄 Importa AdDetails

// Styled Components
const AdContainer = styled.div`
    padding: 24px 16px;  /* 🔄 Più spazio ai lati per immagini */
    background-color: #fff;
    border-radius: 24px 24px 0 0;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;



const Header = styled.div`
    display: flex;
    justify-content: center;  /* 🔄 Centra il titolo */
    align-items: center;
    padding: 16px 0;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    position: sticky;
    top: 0;
    z-index: 10;
`;




const LoaderContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 20;
`;

const AdDetailChatMobile = ({ selectedMessage, onClose, open }) => {
    const { fetchAnnuncioCorrente, annuncio, loadingAnnuncio, error } = useAnnuncio();
    const [loadedAnnuncioId, setLoadedAnnuncioId] = useState(null);

    // 🔄 Recupera i dettagli dell'annuncio quando cambia `selectedMessage`
    useEffect(() => {
        const getAnnuncioDetails = async () => {
            if (selectedMessage?.annuncio && selectedMessage.annuncio !== loadedAnnuncioId) {
                console.log("📌 ID Annuncio selezionato:", selectedMessage.annuncio);
                const response = await fetchAnnuncioCorrente(selectedMessage.annuncio);
                console.log("📥 Risposta Annuncio:", response);
                setLoadedAnnuncioId(selectedMessage.annuncio);
            }
        };
        getAnnuncioDetails();
    }, [selectedMessage, fetchAnnuncioCorrente, loadedAnnuncioId]);

    if (loadingAnnuncio) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                TransitionComponent={(props) => <Slide direction="up" {...props} />}
                PaperProps={{ style: { borderRadius: '16px 16px 0 0' } }}
            >
                <LoaderContainer>
                    <CircularProgress />
                </LoaderContainer>
            </Dialog>
        );
    }

    if (error) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
                TransitionComponent={(props) => <Slide direction="up" {...props} />}
                PaperProps={{ style: { borderRadius: '16px 16px 0 0' } }}
            >
                <AdContainer>
                    <Typography variant="body2" style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>
                        Errore nel caricamento dell'annuncio: {error}
                    </Typography>
                </AdContainer>
            </Dialog>
        );
    }

    if (!annuncio) {
        return (
            <Dialog
                open={open}
                onClose={onClose}
                TransitionComponent={(props) => <Slide direction="up" {...props} />}
                PaperProps={{
                    style: {
                        borderRadius: '24px 24px 0 0',  // 🔄 Angoli più arrotondati
                        marginTop: '20px',               // 🔄 Margine dall'alto
                        maxHeight: '90vh',               // 🔄 Altezza massima per evitare di coprire tutto
                        pointerEvents: open ? 'auto' : 'none'
                    }
                }}
            >
                <AdContainer>
                    <Typography variant="body2" style={{ textAlign: 'center', color: '#888', marginTop: '20px' }}>
                        Seleziona una conversazione per vedere i dettagli dell'annuncio
                    </Typography>
                </AdContainer>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            TransitionComponent={(props) => <Slide direction="up" {...props} />}
            PaperProps={{ style: { borderRadius: '16px 16px 0 0' } }}
        >
            <AdContainer>
<CloseButton onClick={onClose} style={{ position: 'absolute', top: '16px', right: '16px', zIndex: 20 }}>
    <CloseIcon style={{ fontSize: '24px' }} />
</CloseButton>

<Header>
    <Typography variant="h6">Annuncio</Typography>
</Header>


                {/* 🔄 Gestione delle immagini */}
<Box style={{ marginBottom: '16px', borderRadius: '8px', overflow: 'hidden' }}>
    {annuncio.images_urls && Array.isArray(annuncio.images_urls) && annuncio.images_urls.length > 0 ? (
        <CardAdImages 
            images={annuncio?.images_urls || []} 
            mainImage={annuncio?.img_main_url} 
        />
    ) : (
        <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', padding: '16px' }}>
            Nessuna immagine disponibile
        </Typography>
    )}
</Box>


                <Typography variant="h6" style={{ margin: '16px 0' }}>Dettagli Annuncio</Typography>
                <Typography variant="h5" style={{ marginBottom: '8px' }}>{annuncio.titolo}</Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '16px' }}>
                    {annuncio.descrizione}
                </Typography>
                <Typography variant="h6" color="primary" style={{ marginBottom: '16px' }}>
                    €{annuncio.prezzo}
                </Typography>

                <Divider style={{ margin: '16px 0' }} />

                <AdDetails
                    condizioni={annuncio.condizioni}
                    spedizione={annuncio.spedizione_digitale ? "Sì" : "No"}
                    reso={annuncio.reso_oggetto ? "Sì" : "No"}
                    vertical={true}
                />
            </AdContainer>
        </Dialog>
    );
};

export default AdDetailChatMobile;
