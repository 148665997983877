import React from "react";
import styled from "styled-components";


// Styled Components
const AdImgPageWrapper = styled.div`
  display: flex;
  width: 1260px;
  height: 633px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const ImgWrapper = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: row;
  width: 1260px;
  height: 526px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const MainImg = styled.img`
  width: 615px;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 0px 0px 8px;
`;

const GridImgs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 9px;
  width: 607px;
  height: 100%;
`;

const ImgContainer = styled.div`
  width: 299px;
  height: 253px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Img02 = styled(ImgContainer)`
  border-radius: 0px 8px 0px 0px;
`;

const Img04 = styled(ImgContainer)`
  border-radius: 0px 0px 8px 0px;
`;

const AdImgPage = ({ images = [] }) => {
  return (
    <AdImgPageWrapper>
      {/* Sezione immagini */}
      <ImgWrapper>
        <MainImg src={images[0] || "https://via.placeholder.com/615x526"} alt="Main Image" />
        <GridImgs>
          <ImgContainer>
            <img src={images[1] || "https://via.placeholder.com/299x253"} alt="Immagine 1" />
          </ImgContainer>
          <Img02>
            <img src={images[2] || "https://via.placeholder.com/299x253"} alt="Immagine 2" />
          </Img02>
          <ImgContainer>
            <img src={images[3] || "https://via.placeholder.com/299x260"} alt="Immagine 3" />
          </ImgContainer>
          <Img04>
            <img src={images[4] || "https://via.placeholder.com/299x260"} alt="Immagine 4" />
          </Img04>
        </GridImgs>
      </ImgWrapper>
    </AdImgPageWrapper>
  );
};

export default AdImgPage;
