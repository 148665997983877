import React, { useState, useRef } from "react";
import styled from "styled-components";

// Styled Components
const CardAdImageWrapper = styled.div`
  position: relative;
  width: 310px;
  height: 293px;
  border-radius: 12px;
  overflow: hidden;
`;

const CardAdImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Nasconde la scrollbar su Firefox */
  -ms-overflow-style: none; /* Nasconde la scrollbar su Edge */

  &::-webkit-scrollbar {
    display: none; /* Nasconde la scrollbar su Chrome, Safari */
  }
`;

const CardAdImage = styled.img`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 48px; /* ✅ Aumentata dimensione delle frecce */
  height: 48px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.left {
    left: 5px;
  }

  &.right {
    right: 5px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Bullets = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => (props.active ? "#000" : "#ccc")};
    transition: background 0.3s ease;
  }
`;

const CardAdImages = ({ images = [], mainImage }) => {
    console.log("🚀 Entrato in CardAdImages");
  console.log("📦 Props ricevute:", { images, mainImage });
  const imageContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Unisce img_main_url come prima immagine, se disponibile
const allImages = images.length > 0 ? images : mainImage ? [mainImage] : [];

    console.log("🖼️ Immagini:", allImages);
console.log("🖼️ Immagine principale:", mainImage);

  const nextImage = (event) => {
    event.stopPropagation(); // ✅ Blocca la propagazione del click
    if (currentIndex < allImages.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      scrollToImage(newIndex);
    }
  };

  const prevImage = (event) => {
    event.stopPropagation(); // ✅ Blocca la propagazione del click
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      scrollToImage(newIndex);
    }
  };

  const scrollToImage = (index) => {
    if (imageContainerRef.current) {
      const container = imageContainerRef.current;
      const imageWidth = container.clientWidth;
      container.scrollTo({
        left: index * imageWidth,
        behavior: "smooth",
      });
    }
  };

  if (!allImages || allImages.length === 0) {
    console.log("❌ Nessuna immagine trovata. Mostro il placeholder.");


    return (
      <CardAdImageWrapper>
        <CardAdImage
          src="https://via.placeholder.com/310x293"
          alt="Immagine non disponibile"
        />
      </CardAdImageWrapper>
    );
  }

  return (
    <CardAdImageWrapper>
      <CardAdImageContainer ref={imageContainerRef}>
        {allImages.map((image, index) => (
          <CardAdImage key={index} src={image} alt={`Image ${index + 1}`} />
        ))}
      </CardAdImageContainer>

      {/* Frecce di navigazione */}
      <ArrowButton
        className="left"
        show={currentIndex > 0}
        onClick={prevImage} // ✅ Impedisce apertura annuncio
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none">
          <path
            d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
            fill="#F5F5F2"
            fillOpacity="0.63"
          />
          <path
            d="M19 13L14.5 18L19 23"
            stroke="#1D1D1B"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </ArrowButton>

      <ArrowButton
        className="right"
        show={currentIndex < allImages.length - 1}
        onClick={nextImage} // ✅ Impedisce apertura annuncio
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none">
          <path
            d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
            fill="#F5F5F2"
            fillOpacity="0.63"
          />
          <path
            d="M17 13L21.5 18L17 23"
            stroke="#1D1D1B"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </ArrowButton>

      {/* Bullets */}
      <Bullets>
        {allImages.map((_, index) => (
          <span key={index} active={currentIndex === index} />
        ))}
      </Bullets>
    </CardAdImageWrapper>
  );
};

export default CardAdImages;
