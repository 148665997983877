import { createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "../api/axiosConfig";
import { STEPS_CONFIG, STEP_COMPLETION_STATUS } from "../utils/stepsConfig";

const AnnuncioContext = createContext();

export const AnnuncioProvider = ({ children }) => {
  const [error, setError] = useState ()

  const [annuncioTipo, setAnnuncioTipo] = useState(null); // "creazione", "bozza", "modifica"
  const [annuncioStep, setAnnuncioStep] = useState(0); // Step attuale
  const [stepStatus, setStepStatus] = useState({ ...STEP_COMPLETION_STATUS }); // Stato completamento

  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false); // Stato finestra di conferma uscita
  const [exitAction, setExitAction] = useState(null); // Azione da eseguire in caso di conferma
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // ✅ Modale per conferma modifica
  
  const [annuncioSelezionato, setAnnuncioSelezionato] = useState(null); // ✅ Annuncio in modifica
  const [loadingBozza, setLoadingBozza] = useState(false); // ✅ Stato di caricamento
  const [loadingAnnuncio, setLoadingAnnuncio] = useState(false); // ✅ Stato di caricamento dell'annuncio
  const [categoriaLocal, setCategoriaLocal] = useState(""); // ✅ Stato per la categoria
  const [condizioni, setCondizioni] = useState([]);
  
  const [annuncio, setAnnuncio] = useState({
    id: null,
    titolo: "",
    descrizione: "",
    condizioni: "",
    spedizione_digitale: false,
    reso_oggetto: false,
    keyword: [], 
    prezzo: "",
    categoria: "",  // ✅ Non usare `categoriaLocal` direttamente
    categoria_personalizzata: null,
    img_main_url: "", // ✅ Evita errori con immagini non presenti
    images_urls: [],
    is_owner: false,
    venditore: {
      id: null,
      username: "",
      email: "",
      avatar: "https://via.placeholder.com/50",
      nome: "Venditore sconosciuto",
      cognome: "",
      nome_completo: "Venditore sconosciuto",
      is_privato: false,
      citta: "",
      numero_di_recensioni: 0,
      media_valutazioni: null,
      latitudine: null,
      longitudine: null,
    },
  });


  const steps = STEPS_CONFIG[annuncioTipo];


  const startCreatingAnnuncio = () => {
    setAnnuncioTipo("creazione");
    setAnnuncioStep(0);
  };



   {/* 📌 Recupero Annuncio approvato (Frontend)
   */}
  
const fetchAnnuncioCorrente = async (annuncioId) => {
    if (!annuncioId) {
        console.warn("⚠️ Nessun annuncio_id trovato, chiamata annullata.");
        return null;
    }

    console.log(`📌 Tentativo di recupero annuncio ID: ${annuncioId}`);
    setLoadingAnnuncio(true);
    setError(null);

    try {
        const response = await axiosInstance.get(`/annuncio/${annuncioId}/`);
        const annuncioData = {
            ...response.data,
            keyword: Array.isArray(response.data.keyword)
                ? response.data.keyword
                : response.data.keyword
                ? response.data.keyword.split(",").map((k) => k.trim())
                : [],
            venditore: {
                ...response.data.venditore,
                nomeCompleto: response.data.venditore?.nome || "Venditore sconosciuto",
            },
        };

        setAnnuncio(annuncioData);
        console.log("✅ Annuncio caricato:", annuncioData);
        return annuncioData;  // 🔄 Restituisce i dati dell'annuncio
    } catch (err) {
        console.error("❌ Errore nel recupero dell'annuncio:", err.response?.data || err);
        setError("Impossibile caricare l'annuncio.");
        return null;  // 🔄 Restituisce null in caso di errore
    } finally {
        setLoadingAnnuncio(false);
    }
};



const recuperoAnnuncio = async (annuncioId, statoAnnuncio = "Bozza") => {
  setLoadingAnnuncio(true);

  if (!annuncioId) {
    console.warn("⚠️ Nessun ID fornito per il recupero dell'annuncio!");
    setLoadingAnnuncio(false);
    return;
  }

  // Determina l'endpoint corretto in base allo stato dell'annuncio
  const url = statoAnnuncio === "Bozza"
    ? `/bozza_annuncio/${annuncioId}/`   // Endpoint per bozze
    : `/annuncio/${annuncioId}/`;        // Endpoint per annunci Approvati o Rifiutati

  try {
    const response = await axiosInstance.get(url);
    console.log(`📌 Dati annuncio (${statoAnnuncio}) recuperati:`, response.data);


    const keywordsArray = Array.isArray(response.data.keyword)
      ? response.data.keyword
      : response.data.keyword
      ? response.data.keyword.split(",").map((k) => k.trim())
      : [];

    setAnnuncio({
      ...response.data,
      keyword: keywordsArray, // ✅ Trasformato in array
    });
    
    setAnnuncioSelezionato(response.data);

    if (response.data.categoria) {
      setCategoriaLocal(response.data.categoria);
    }
  } catch (error) {
    console.error("❌ Errore nel recupero dell'annuncio:", error.response?.data || error);
  }

  setLoadingAnnuncio(false);
};






  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (annuncioTipo) {
        event.preventDefault();
        event.returnValue = ""; // Mostra la finestra di conferma del browser
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [annuncioTipo]);




  // Recupera le condizioni dal modello dell'annuncio dal backend


    useEffect(() => {
    axiosInstance.get("/condizioni/")
      .then(response => {
        setCondizioni(response.data);
      })
      .catch(error => {
        console.error("❌ Errore nel recupero delle condizioni:", error);
      });
  }, []);


    // Dialog Section Annuncio Approvato in Modifica

  // 🔹 Mostra il dialog di conferma uscita
  const confirmExitAnnuncio = (action) => {
    setIsExitDialogOpen(true);
    setExitAction(() => action);
  };

  // 🔹 Conferma uscita
  const handleExitConfirmed = () => {
    if (exitAction) exitAction();
    setIsExitDialogOpen(false);
  };

  // 🔹 Annulla uscita
  const handleExitCancelled = () => {
    setIsExitDialogOpen(false);
  };


const changeStatusAnnuncio = async (annuncioId, nuovoStato = "Bozza") => {
  try {
    const response = await axiosInstance.post("/change_status/", {
      id: annuncioId,
      stato: nuovoStato,
    });

    console.log(`🔄 Stato aggiornato: Annuncio ${annuncioId} ora è ${nuovoStato}`);

    // Aggiorna lo stato localmente con quello ricevuto
    setAnnuncio((prev) => ({
      ...prev,
      stato: nuovoStato,
    }));

    // Aggiorna annuncioId solo se restituito dal backend
    if (response.data.annuncio_id) {
      localStorage.setItem("annuncioId", response.data.annuncio_id);
    }

    return true;
  } catch (error) {
    console.error("❌ Errore nel cambio di stato:", error.response?.data || error);
    return false;
  }
};




    // 🔹 Imposta il tipo di annuncio e resetta gli step
  const setTipoAnnuncio = (tipo) => {
    setAnnuncioTipo(tipo);
    setAnnuncioStep(0);
    setStepStatus(
      tipo === "modifica"
        ? Object.keys(STEP_COMPLETION_STATUS).reduce((acc, key) => ({ ...acc, [key]: true }), {})
        : { ...STEP_COMPLETION_STATUS }
    );

        // ✅ Reset categoria solo se si sta creando un nuovo annuncio o modificando uno rifiutato
    if (tipo === "creazione" || tipo === "rifiutato") {
      setCategoriaLocal("");
    }
  };

  


  // ✅ Recupera la categoria dal backend se in modalità modifica
  useEffect(() => {
    if (annuncioTipo === "modifica" && annuncioSelezionato) {
      console.log("📌 Recupero dati da annuncio in modifica:", annuncioSelezionato);
      
      // Aggiorna l'intero annuncio, non solo la categoria
      setAnnuncio((prev) => ({
        ...prev,
        ...annuncioSelezionato, // ✅ Copia tutti i dati dell'annuncio selezionato
      }));

      // Aggiorna anche la categoria
      setCategoriaLocal(annuncioSelezionato.categoria || "");
    }
  }, [annuncioTipo, annuncioSelezionato]);



/**
 * 📌 Recupera una bozza dal backend
 */

  /**
   * 📌 Crea una nuova bozza
   */
const creaBozza = async () => {
  setLoadingBozza(true);
  
  const categoriaToSend = annuncio.categoria || categoriaLocal;

  if (!annuncio.titolo || !categoriaToSend) {
    console.error("⚠️ Titolo o categoria mancanti");
    setLoadingBozza(false);
    return;
  }

  try {
    const response = await axiosInstance.post("/crea_bozza/", {
      titolo: annuncio.titolo,
      categoria: categoriaToSend,
    });

    const newAnnuncioId = response.data.annuncio_id;
    console.log("✅ Bozza creata con successo:", newAnnuncioId);

    localStorage.setItem("annuncioId", newAnnuncioId);

    // aggiorniamo lo stato includendo la categoria corretta
    setAnnuncio((prev) => ({ 
      ...prev, 
      id: newAnnuncioId,
      categoria: categoriaToSend
    }));

  } catch (error) {
    console.error("❌ Errore nella creazione della bozza:", error.response?.data || error);
  }
  
  setLoadingBozza(false);
};



  /**
   * 📌 Salva aggiornamenti nella bozza
   */


const salvaBozza = async () => {
  setLoadingBozza(true);
  const annuncioId = localStorage.getItem("annuncioId");

  console.log("📌 Prima di salvare - annuncioId da localStorage:", annuncioId);

  if (!annuncioId) {
    console.error("❌ Errore: Nessuna bozza trovata!");
    setLoadingBozza(false);
    return;
  }

  try {
    const response = await axiosInstance.post("/salva_bozza/", {
      id: annuncioId,
      titolo: annuncio.titolo,
      descrizione: annuncio.descrizione,
      condizioni: annuncio.condizioni,
      spedizione_digitale: annuncio.spedizione_digitale,
      reso_oggetto: annuncio.reso_oggetto,
      keyword: Array.isArray(annuncio.keyword) ? annuncio.keyword.join(", ") : annuncio.keyword,
      prezzo: annuncio.prezzo,
    });

    console.log("✅ Bozza aggiornata con successo:", response.data);

    if (response.data.annuncio_id) {
      console.log("📌 Nuovo annuncio ID ricevuto:", response.data.annuncio_id);
      localStorage.setItem("annuncioId", response.data.annuncio_id);
      setAnnuncio((prev) => ({ ...prev, id: response.data.annuncio_id }));
    }

  } catch (error) {
    console.error("❌ Errore nel salvataggio della bozza:", error.response?.data || error);
  }
  setLoadingBozza(false);
};


const stopCreatingAnnuncio = () => {
  setAnnuncioTipo(null);
  setAnnuncioStep(0);
  setStepStatus({ ...STEP_COMPLETION_STATUS });
  setAnnuncioSelezionato(null);
  setCategoriaLocal("");
  setAnnuncio({
    id: null,
    titolo: "",
    descrizione: "",
    condizioni: "",
    spedizione_digitale: false,
    reso_oggetto: false,
    keyword: [],
    prezzo: "",
    categoria: "",
    categoria_personalizzata: null,
    img_main_url: "",
    images_urls: [],
    is_owner: false,
    venditore: {
      id: null,
      username: "",
      email: "",
      avatar: "https://via.placeholder.com/50",
      nome: "Venditore sconosciuto",
      cognome: "",
      nome_completo: "Venditore sconosciuto",
      is_privato: false,
      citta: "",
      numero_di_recensioni: 0,
      media_valutazioni: null,
      latitudine: null,
      longitudine: null,
    },
  });

  localStorage.removeItem("annuncioId"); // se vuoi cancellare anche la bozza locale
  console.log("🚪 Creazione annuncio interrotta.");
};



  // 🔹 Avvia la modifica dell'annuncio selezionato
  const avviaModificaAnnuncio = (annuncio) => {
    if (!annuncio) return;

    setAnnuncioSelezionato(annuncio);

    // ✅ Se Approvato, mostra la modale prima di cambiare stato
    if (annuncio.stato === "Approvato") {
      setIsEditModalOpen(true);
    } else {
      // ✅ Se Bozza o Rifiutato, si procede direttamente con la modifica
      confermaModifica();
    }
  };

  // 🔹 Funzione per confermare la modifica e (se serve) cambiare stato a bozza
  const confermaModifica = async () => {
    setIsEditModalOpen(false);

    if (!annuncioSelezionato) return;

    if (annuncioSelezionato.stato === "Approvato" || annuncioSelezionato.stato === "Rifiutato") {
      try {
        await axiosInstance.post("/salva_bozza/", {
          id: annuncioSelezionato.id,
        });
        console.log(`🔄 Annuncio ${annuncioSelezionato.id} ora è una bozza`);
      } catch (error) {
        console.error("❌ Errore nel passaggio a bozza:", error);
        return;
      }
    }

    // ✅ Passa all'editor di bozza/modifica
    setTipoAnnuncio("modifica");
    console.log(`📝 Avvio editor per annuncio ID: ${annuncioSelezionato.id}`);
  };

  

  return (
    <AnnuncioContext.Provider
      value={{
        fetchAnnuncioCorrente,
        annuncioTipo,
        annuncioStep,
        steps,
        stepStatus,
        setAnnuncioStep,
        setTipoAnnuncio,
        confirmExitAnnuncio,
        isExitDialogOpen,
        handleExitConfirmed,
        handleExitCancelled,
        loadingBozza,
        recuperoAnnuncio,
        changeStatusAnnuncio,
        startCreatingAnnuncio,
        creaBozza,
        salvaBozza,
        stopCreatingAnnuncio,
        avviaModificaAnnuncio,
        isEditModalOpen,
        setIsEditModalOpen,
        confermaModifica,
        annuncioSelezionato, // ✅ Annuncio selezionato
        setAnnuncioSelezionato, // ✅ Funzione per impostarlo manualmente
        loadingAnnuncio, // ✅ Stato di caricamento
        categoriaLocal, // ✅ Stato della categoria
        setCategoriaLocal, // ✅ Funzione per modificarla
        condizioni,
        annuncio,
        setAnnuncio,
        
      }}
    >
      {children}
    </AnnuncioContext.Provider>
  );
};

export const useAnnuncio = () => useContext(AnnuncioContext);
