import React, { useState } from "react";
import styled from "styled-components";
import axiosInstance, {getCookie} from "../../api/axiosConfig"; // 🔹 Importa istanza axios
import { useUser } from "../../context/UserContext";


import UserIcon from "../../assets/icons/user.svg";
import LockIcon from "../../assets/icons/lockIcon.svg";
import EyeIcon from "../../assets/icons/eye.svg"; // Nuova icona per mostrare la password
import EyeOffIcon from "../../assets/icons/eyeOff.svg"; // Icona per nascondere la password

import RegisterForm from "./RegisterFormMobile"; // Import del RegisterForm

// Styled Components

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

// Icona di caricamento (puoi sostituirla con un'immagine se preferisci)
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoginFormMobileWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px; /* Spazio per la NavbarMobileBottom */
  background-color: ${(props) => props.theme.palette.background.default};
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginFormContainer = styled.div`
  width: auto;
  max-width: 360px;
  padding: 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 16px;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  margin: 8px 0;
`;

const HeaderForm = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
  text-align: left;
`;

const FormWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Form = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FieldIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const FieldText = styled.input`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
  border: none;
  outline: none;
  flex: 1;

  &::placeholder {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const Conditions = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
`;

const LoginButton = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
`;

const TextButton = styled.div`
  color: #fff;
  font-family: "PP Gosha Sans";
  font-size: 16px;
  font-weight: 700;
`;

const WrapperRegistrati = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
`;

const BreakLineL = styled.div`
  width: 80px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const BreakLineR = styled.div`
  width: 80px;
  height: 0px;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const Text = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: "PP Gosha Sans";
  font-size: 12px;
  font-weight: 400;
`;

// 🔹 Messaggio di errore stile
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
`;

// 🔹 Stato `loading` stile
const LoadingMessage = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
`;

const TogglePasswordButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  
`;

const LoginFormMobile = ({ onClose }) => {
  const [isRegister, setIsRegister] = useState(false);
  const { handleLoginSuccess } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Nuovo stato per la visualizzazione della password

const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // 🔥 Miglioramento: Resetta l'errore precedente

    const csrftoken = getCookie('csrftoken');
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 🔥 Timeout di 10 secondi

    try {
        const response = await axiosInstance.post("/login/", {
            username_or_email: email,
            password: password,
        }, {
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            signal: controller.signal,
        });

        clearTimeout(timeoutId);

      if (response.data.access) {
          handleLoginSuccess(response.data.user.id, response.data.user.username, response.data.user.email, response.data.access, response.data.refresh);
          localStorage.setItem("access_token", response.data.access);
          onClose();
} else {
    setError("⚠️ Credenziali non valide. Controlla l'email e la password.");
}

} catch (error) {
    console.error("❌ Errore durante il login:", error);

    if (error.name === "AbortError") {
        setError("⏳ Tempo scaduto. Verifica la connessione e riprova.");
    } else if (error.response) {
        const { status, data } = error.response;

        switch (status) {
            case 400:
                if (data?.errors) {
                    const errorMessages = Object.values(data.errors).flat().join(" ");
                    setError(`⚠️ Errore di validazione: ${errorMessages}`);
                } else {
                    setError("⚠️ Dati inseriti non validi. Controlla il formato e riprova.");
                }
                break;
            
            case 401:
                setError("🔒 Email o password errati. Verifica e riprova.");
                break;
            
            case 403:
                setError("🚫 Accesso negato. Controlla le credenziali o verifica il tuo account.");
                break;

            case 404:
                setError("❌ Servizio non disponibile. Il server non ha trovato la risorsa richiesta.");
                break;

            case 429:
                setError("⚠️ Troppe richieste. Attendi qualche minuto prima di riprovare.");
                break;

            case 500:
                setError("🛠 Errore del server. Riprova più tardi o contatta l'assistenza.");
                break;

            default:
                setError(data?.message || "❓ Errore sconosciuto. Riprova più tardi.");
          }
      } else if (error.request) {
          setError("📡 Nessuna risposta dal server. Verifica la tua connessione internet.");
      } else {
          setError("❌ Errore imprevisto. Riprova più tardi.");
      }
    } finally {
        setLoading(false);
    }
};
const ErrorModal = ({ message, onClose }) => (
    <LoaderOverlay onClick={onClose}>
        <div style={{ padding: '20px', background: '#fff', borderRadius: '8px', textAlign: 'center', boxShadow: '0 0 10px rgba(0,0,0,0.3)' }}>
            <h4 style={{ margin: '0 0 10px 0', color: '#D32F2F' }}>Errore</h4>
            <p>{message}</p>
            <button onClick={onClose} style={{ marginTop: '10px', padding: '5px 10px', cursor: 'pointer' }}>Chiudi</button>
        </div>
    </LoaderOverlay>
);



  return (
<>
            {loading && (
      <LoaderOverlay>
        <Spinner />
      </LoaderOverlay>
      )}

    <LoginFormMobileWrapper>
      {isRegister ? (
        <RegisterForm onBack={() => setIsRegister(false)} />
      ) : (
        <LoginFormContainer>
          <Title>Accedi o Registrati</Title>
          <BreakLine />

          <HeaderForm>Ciao! Ti diamo il benvenuto su Perstudenti.com</HeaderForm>

          <FormWrapper>
            <Form>
              <FieldWrapper>
                <FieldIcon src={UserIcon} alt="User Icon" />
                <FieldText type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </FieldWrapper>
              <BreakLine />
              <FieldWrapper>
                <FieldIcon src={LockIcon} alt="Lock Icon" />
                <FieldText type={showPassword ? "text" : "password"} placeholder="Inserisci la tua Password" value={password} onChange={(e) => setPassword(e.target.value)} hasError={error.includes("password")} />
              <TogglePasswordButton onClick={() => setShowPassword(!showPassword)}>
                <img src={showPassword ? EyeOffIcon : EyeIcon} alt="Toggle Password Visibility" />
              </TogglePasswordButton>
              </FieldWrapper>
            </Form>
            <Conditions>Informativa Privacy</Conditions>
          </FormWrapper>

        {error && <ErrorModal message={error} onClose={() => setError("")} />}

          <LoginButton onClick={handleLogin}>
              {loading ? (
                  <Spinner style={{ borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 0.1)', borderLeftColor: '#fff' }} />
              ) : (
                  <TextButton>Accedi</TextButton>
              )}          </LoginButton>

          <WrapperRegistrati>
            <BreakLineL />
            <Text onClick={() => setIsRegister(true)}>o Registrati</Text>
            <BreakLineR />
          </WrapperRegistrati>
        </LoginFormContainer>
      )}
    </LoginFormMobileWrapper>
    </>
  );
};

export default LoginFormMobile;
