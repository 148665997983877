import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, Button, IconButton, CircularProgress } from '@mui/material';
import { useAnnuncio } from '../../context/AnnuncioContext';

import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RoundButton from '../RoundButton';
import styled from 'styled-components';
import CardAdImages from '../Ad/CardAdImages';
import AdDetails from '../Ad/AdDetails';  // 🔄 Importa AdDetails


const AdContainer = styled.div`
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const AdDetailChat = ({ selectedMessage, onClose }) => {
    const { fetchAnnuncioCorrente, annuncio, loadingAnnuncio, error } = useAnnuncio();
    const [loadedAnnuncioId, setLoadedAnnuncioId] = useState(null);

    // 🔄 Recupera i dettagli dell'annuncio quando cambia `selectedMessage`
useEffect(() => {
    const getAnnuncioDetails = async () => {
        if (selectedMessage?.annuncio && selectedMessage.annuncio !== loadedAnnuncioId) {
            console.log("📌 ID Annuncio selezionato:", selectedMessage.annuncio);
            const response = await fetchAnnuncioCorrente(selectedMessage.annuncio);
            console.log("📥 Risposta Annuncio:", response); // ✅ Log dell'intera risposta
            setLoadedAnnuncioId(selectedMessage.annuncio);
            
        }
    };
    getAnnuncioDetails();
}, [selectedMessage, fetchAnnuncioCorrente, loadedAnnuncioId]);



useEffect(() => {
    if (annuncio) {
        console.log("✅ Annuncio caricato:", annuncio);
    }
}, [annuncio]);

    if (loadingAnnuncio) {
        return (
            <AdContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            </AdContainer>
        );
    }

    if (error) {
        return (
            <AdContainer>
                <Typography variant="body2" style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>
                    Errore nel caricamento dell'annuncio: {error}
                </Typography>
            </AdContainer>
        );
    }

    if (!annuncio) {
        return (
            <AdContainer>
                <Typography variant="body2" style={{ textAlign: 'center', color: '#888', marginTop: '20px' }}>
                    Seleziona una conversazione per vedere i dettagli dell'annuncio
                </Typography>
            </AdContainer>
        );
    }



    return (
        <AdContainer>
            <Header>
                <Typography variant="h6">Annuncio</Typography>
                <RoundButton onClick={onClose}>
                    <CloseIcon />
                </RoundButton>
            </Header>

            {/* 🔄 Gestione delle immagini */}
            
            {annuncio.images_urls && Array.isArray(annuncio.images_urls) && annuncio.images_urls.length > 0 ? (
                
                <CardAdImages 
                images={annuncio?.images_urls || []} 
                mainImage={annuncio?.img_main_url} 
            />


            ) : (
                <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', marginBottom: '16px' }}>
                    Nessuna immagine disponibile
                </Typography>
            )}

            <Typography variant="h6" style={{ margin: '16px 0' }}>Dettagli Annuncio</Typography>
            <Typography variant="h5" style={{ marginBottom: '8px' }}>{annuncio.titolo}</Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '16px' }}>
                {annuncio.descrizione}
            </Typography>
            <Typography variant="h6" color="primary" style={{ marginBottom: '16px' }}>
                €{annuncio.prezzo}
            </Typography>

            <Divider style={{ margin: '16px 0' }} />

                <AdDetails
                    condizioni={annuncio.condizioni}
                    spedizione={annuncio.spedizione_digitale ? "Sì" : "No"}  // 🔄 Usa il campo corretto per la spedizione
                    reso={annuncio.reso_oggetto ? "Sì" : "No"}  // 🔄 Usa `reso_oggetto` dal modello per il reso
                    vertical={true}
                />

        </AdContainer>
    );
};

export default AdDetailChat;
