import React, { useState, useEffect } from "react";
import { Box, Typography, Chip, Grid, CircularProgress, Alert } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useUser } from "../context/UserContext";
import { useAnnuncio } from "../context/AnnuncioContext";
import axiosInstance from "../api/axiosConfig";

import CardProduct from "./Ad/CardProduct";
import AdDraftIcon from "../assets/icons/AdDraft.svg";
import AdApprovedIcon from "../assets/icons/AdApproved.svg";
import Modal from "../components/Modal"; // ✅ Importiamo la modale
import GestisciAnnunciMobile from "./Mobile/GestisciAnnuncioMobile";

// Mappa tra frontend e backend per gli stati dell'annuncio
const STATUS_MAP = {
  "Pubblicati": "Approvato",
  "In approvazione": "Da approvare",
  "Bozza": "Bozza",
  "Rifiutato": "Rifiutato" // Aggiunto per eventuale gestione degli annunci rifiutati
};

const GestisciAnnunci = () => {
  const { user } = useUser();
  const { avviaModificaAnnuncio, setTipoAnnuncio, setAnnuncioStep, recuperoAnnuncio, isEditModalOpen, setIsEditModalOpen, confermaModifica } = useAnnuncio();  const [selectedFilter, setSelectedFilter] = useState("Pubblicati");
  const [annunci, setAnnunci] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [counts, setCounts] = useState({ Pubblicati: 0, "In approvazione": 0, Bozza: 0, Rifiutato: 0 });
  const isMobile = useMediaQuery("(max-width:768px)");

  
  // Filtri basati sui valori del backend
  const filters = Object.keys(STATUS_MAP);

  // Funzione per recuperare gli annunci filtrati
  const fetchAnnunci = async (status) => {
    setLoading(true);
    setError(null);
    try {

      const backendStatus = STATUS_MAP[status]; // Converte lo stato nel valore del backend
      const response = await axiosInstance.get(`/i_miei_annunci/?stato=${encodeURIComponent(backendStatus)}`);

              // ✅ Log dettagliato per ogni annuncio
        response.data.results.forEach(annuncio => {
            console.log(`🔍 Annuncio ID: ${annuncio.id}`);
            console.log(`🏷 Titolo: ${annuncio.titolo}`);
            console.log(`📦 Pacchetto Premium (premium_package):`, annuncio.premium_package);
            console.log(`🎟 Selected Package (selected_package):`, annuncio.selected_package);
        });

      setAnnunci(response.data.results);
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.detail || "Errore nel recupero degli annunci. Riprova più tardi.");
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
  console.log("🔍 Annunci ricevuti dal backend:", annunci);
}, [annunci]);  // ✅ Si attiva SOLO quando `annunci` cambia


  // Funzione per contare gli annunci in ogni stato
  const fetchAnnunciCounts = async () => {
    try {
      const promises = filters.map(async (status) => {
        const backendStatus = STATUS_MAP[status];
        const response = await axiosInstance.get(`/i_miei_annunci/?stato=${encodeURIComponent(backendStatus)}`);

        return { status, count: response.data.results.length };
      });

      const results = await Promise.all(promises);
      const countsObj = results.reduce((acc, { status, count }) => ({ ...acc, [status]: count }), {});
      setCounts(countsObj);
    } catch (err) {
      console.error("Errore nel recupero del conteggio degli annunci:", err);
    }
  };

const handleClickAnnuncio = async (annuncio) => {
  let nuovoTipo = "draft"; // default per le bozze

  if (annuncio.stato === "Approvato") nuovoTipo = "modifica";
  else if (annuncio.stato === "Rifiutato") nuovoTipo = "rifiutato";

  setTipoAnnuncio(nuovoTipo);
  localStorage.setItem("annuncioId", annuncio.id);

  // ✅ Chiama endpoint differenziato automaticamente in base allo stato
  await recuperoAnnuncio(annuncio.id, annuncio.stato);

  // ✅ Imposta lo step iniziale giusto secondo lo stato
  const stepIniziale = {
    modifica: 0,  // StepDatiAnnuncio per "Approvato"
    rifiutato: 0, // StepCategoria per "Rifiutato"
    draft: 0,     // Primo step per bozze
  }[nuovoTipo];

  setAnnuncioStep(stepIniziale);
};



  // Effetto per recuperare i dati all'avvio e quando cambia il filtro
  useEffect(() => {
    if (user.isLoggedIn) {
      fetchAnnunci(selectedFilter);
      fetchAnnunciCounts();
    }
  }, [selectedFilter, user]);


      // Se è mobile, renderizza il componente AdMobilePage
    if (isMobile) return <GestisciAnnunciMobile/>;



  return (
    

    
    <Box sx={{ width: "100%", maxWidth: 1200, mx: "auto", mt: 4 }}>
      {/* Titolo */}
      <Typography variant="h5" fontWeight="bold">
        Ciao, {user.username || "Nome"}
      </Typography>
      <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
        I tuoi annunci
      </Typography>

      {/* Filtri con Chips e Contatore */}
      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
        {filters.map((filter) => (
          <Chip
            key={filter}
            label={`${filter} (${counts[filter] || 0})`}
            color={selectedFilter === filter ? "primary" : "default"}
            onClick={() => setSelectedFilter(filter)}
            sx={{ cursor: "pointer" }}
          />
        ))}
      </Box>

      {/* Caricamento ed Errori */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {error}
        </Alert>
      )}

      {/* Lista annunci */}
      {!loading && !error && (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          {annunci.length > 0 ? (
            annunci.map((annuncio) => {
              let icon = null;
              if (annuncio.stato === "Bozza") {
                icon = <img src={AdDraftIcon} alt="Bozza" width={24} height={24} />;
              } else if (annuncio.stato === "Approvato") {
                icon = <img src={AdApprovedIcon} alt="Approvato" width={24} height={24} />;
              }

              return (
                <Grid item xs={12} key={annuncio.id}>
                  <CardProduct
                    id={annuncio.id}
                    category={annuncio.categoria}
                    images={[annuncio.img_main_url]}
                    title={annuncio.titolo}
                    seller={annuncio.user_username}
                    state={annuncio.condizioni}
                    price={annuncio.prezzo ? `${annuncio.prezzo} €` : "Prezzo non specificato"}
                    sponsored={annuncio.is_featured}
                    premiumPackage={annuncio.premium_package}
                    horizontal={true}
                    icon={icon}
                    onClick={() =>handleClickAnnuncio(annuncio)}
                  />
                </Grid>
              );
            })
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ textAlign: "center", width: "100%", mt: 2 }}
            >
              Nessun annuncio {selectedFilter.toLowerCase()} disponibile.
            </Typography>
          )}
        </Grid>
      )}

      {/* ✅ Modale per conferma modifica annuncio approvato */}
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onConfirm={confermaModifica}
        title="Modifica Annuncio"
        description="Se modifichi questo annuncio, dovrà essere nuovamente approvato. Vuoi procedere?"
        cancelLabel="Chiudi"
        confirmLabel="Modifica"
      />
    </Box>
  );
};

export default GestisciAnnunci;
