import React from "react";
import styled from "styled-components";
import ArrowLeftIcon from "../assets/icons/arrow-left.svg";

// Styled Components
const ButtonWrapper = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.background.paper || "#ddd"};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.palette.background.default || "#ccc"};
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

const RoundButton = ({ icon, onClick, back = false }) => {
  const iconSrc = back ? ArrowLeftIcon : icon;

  return (
    <ButtonWrapper onClick={onClick}>
      <Icon src={iconSrc} alt="button icon" />
    </ButtonWrapper>
  );
};

export default RoundButton;
