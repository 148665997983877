// src/components/ModalRoutes.jsx
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const ModalRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => navigate(-1);
  const currentPath = location.pathname;

  if (currentPath !== "/login" && currentPath !== "/registrati") {
    return null;
  }

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0, 0, 0, 0.4)",
      zIndex: 9999,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      {currentPath === "/login" && <LoginForm onClose={handleClose} />}
      {currentPath === "/registrati" && <RegisterForm onClose={handleClose} />}
    </div>
  );
};

export default ModalRoutes;
