import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useRive, useStateMachineInput } from 'rive-react';

const PopupContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    max-width: 400px;
    z-index: 1000;
`;

const IconWrapper = styled.div`
    width: 64px;
    height: 64px;
    margin-bottom: 16px;

    div {
        width: 100%;
        height: 100%;
    }
`;

const PopupText = styled.h4`
    font-size: ${(props) => props.theme.typography.h4.fontSize};
    font-weight: ${(props) => props.theme.typography.h4.fontWeight};
    color: ${(props) => props.theme.palette.text.primary};
    text-align: center;
    margin-bottom: 16px;
`;

const Popup = ({ animationType, text, buttonLabel, onButtonClick }) => {

const cloudinaryBaseUrl = process.env.REACT_APP_CLOUDINARY_BASE_URL || "/static/static/animations/";
const animationPath = animationType ? `${cloudinaryBaseUrl}${animationType}.riv` : null;

    // Configura l'animazione con State Machine
    const { RiveComponent, rive } = useRive({
        src: animationPath,
        stateMachines: ['StateMachine1'], // Nome della state machine
        autoplay: true,
    });

    

    // Gestione degli input della state machine
    const IsAnimeInput = useStateMachineInput(rive, 'StateMachine1', 'IsAnime'); // Nome del booleano

    useEffect(() => {
        if (!IsAnimeInput) return;

        const timer = setTimeout(() => {
            IsAnimeInput.value = true;
        }, 500);

        return () => clearTimeout(timer);
    }, [IsAnimeInput]);

    return (
        <PopupContainer>
                <IconWrapper>
                    {animationPath && <RiveComponent />}
                </IconWrapper>
            <PopupText>{text}</PopupText>
                <Button variant="contained" onClick={onButtonClick} autoFocus aria-label={buttonLabel}>
                    {buttonLabel}
                </Button>
        </PopupContainer>
    );
};

Popup.propTypes = {
    animationType: PropTypes.string.isRequired, // Nome del file Rive senza estensione
    text: PropTypes.string.isRequired, // Testo del popup
    buttonLabel: PropTypes.string.isRequired, // Testo del pulsante
    onButtonClick: PropTypes.func.isRequired, // Funzione per gestire il click
};

export default Popup;
