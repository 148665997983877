import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 675px;
  padding: 24px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-family: "PP Gosha Sans";
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 12px;
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 12px 0;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;

const MessageBox = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const SendButton = styled.button`
  width: 100%;
  background-color: #34a853;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #2c8c47;
  }
`;

const FormMessage = ({ venditore, titoloAnnuncio, message, setMessage, onSendMessage }) => {

  return (
    <FormWrapper>
      <Title>Contatta {venditore.nome}</Title>
      <Divider />
      <Subtitle>
        Chiedi a <strong>{venditore.nome}</strong> maggiori informazioni riguardo a{" "}
        <strong>{titoloAnnuncio}</strong>
      </Subtitle>

      <Label>Invia un messaggio al venditore</Label>
      <MessageBox
        placeholder={`Ciao ${venditore.nome}, ti chiedo se ...`}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <SendButton onClick={onSendMessage}>Invia messaggio</SendButton>
    </FormWrapper>
  );
};

export default FormMessage;
