import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosConfig";
import ConfirmUserModal from "../components/ConfirmUserModal";

const ConfirmUserPage = () => {

    console.log("🚀 ConfirmUserPage caricato!"); // <-- Debug
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const confirmUser = async () => {
            setLoading(true);  // 🔄 Imposta loading a true prima della richiesta API
            try {
                const response = await axiosInstance.get(`/confirm_user/${uid}/${token}/`);
                console.log("✅ Conferma utente avvenuta con successo:", response.data);
                setSuccess(true);
                setError(null);  // ✅ Assicura che errori precedenti vengano resettati
            } catch (err) {
                console.error("❌ Errore nella conferma dell'utente:", err);
                setError("Il link di conferma non è valido o è scaduto.");
                setSuccess(false); // ✅ Evita che success rimanga true dopo un errore
            } finally {
                setLoading(false);  // ✅ Imposta loading a false dopo la risposta
            }
        };

        confirmUser();
    }, [uid, token]);

    const handleClose = () => {
        setOpen(false);
        navigate("/login"); // Reindirizza alla pagina di login
    };

    return (
        <ConfirmUserModal open={open} handleClose={handleClose} success={success} error={error} loading={loading} />
    );
};

export default ConfirmUserPage;
