import { createTheme } from '@mui/material/styles';

const baseTheme = {
    typography: {
        fontFamily: 'PP Gosha Sans, Arial, sans-serif', // Font principale con fallback
        h1: {
            fontSize: '64px',
            fontWeight: 'bold',
            color: '#34ab6a', // Colore personalizzato
        },
        h2: {
            fontSize: '40px',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '32px',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '1.4rem', // 2xl corrisponde a 1.5rem
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '1rem', // Base size
            fontWeight: 'normal',
        },
        body1: {
            fontSize: '1rem', // Base size
            fontWeight: 'normal',
        },
    },
    components: {
            MuiButton: {
            variants: [
                {
                props: { variant: 'primary' },
                style: ({ theme }) => ({
                    color: theme.palette.primary.button, // bianco
                    backgroundColor: theme.palette.primary.main, // verde
                    fontWeight: 700,
                    textTransform: 'none',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '16px',
                    boxShadow: 'none',
                    '&:hover': {
                    backgroundColor: theme.palette.primary.dark || theme.palette.primary.main, // usa dark se presente
                    boxShadow: 'none',
                    },
                }),
                },
                {
                props: { variant: 'secondary' },
                style: ({ theme }) => ({
                    color: theme.palette.text.primary, // nero
                    backgroundColor: 'transparent',
                    border: `2px solid ${theme.palette.divider}`, // grigio medio per bordo
                    fontWeight: 700,
                    textTransform: 'none',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '16px',
                    '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    },
                }),
                },
                {
                props: { variant: 'inactive' },
                style: ({ theme }) => ({
                    color: theme.palette.text.disabled || theme.palette.text.secondary, // grigio scuro
                    backgroundColor: theme.palette.divider, // grigio medio come sfondo
                    fontWeight: 700,
                    textTransform: 'none',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '16px',
                    pointerEvents: 'none',
                }),
                },
                {
                props: { variant: 'tertiary' },
                style: ({ theme }) => ({
                    color: theme.palette.text.primary, // nero
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    textTransform: 'none',
                    padding: '12px 24px',
                    fontSize: '16px',
                    textDecoration: 'underline',

                }),
                },
                {
                props: { variant: 'tertiaryInactive' },
                style: ({ theme }) => ({
                    color: theme.palette.text.secondary, // grigio scuro
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    textTransform: 'none',
                    padding: '12px 24px',
                    fontSize: '16px',
                    textDecoration: 'underline',
                    pointerEvents: 'none',
                }),
                },
            ],
            },


        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        display: 'flex',
                        width: 'inherit', // Usa la larghezza del genitore
                        height: '56px',
                        padding: '14px 57px 14px 19px',
                        alignItems: 'center',
                        flexShrink: 0,
                        borderRadius: '16px',
                        backgroundColor: '#FFF', // White background
                        transition: 'border-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition

                        '& fieldset': {
                            borderWidth: '2px', // Spessore del bordo
                            borderColor: '#EAEAEA', // Default border color
                            transition: 'border-color 0.3s ease', // Smooth transition
                        },
                        '&:hover fieldset': {
                            borderColor: '#C4C4C4', // Hover border color
                            
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#C4C4C4', // Focus border color
                            boxShadow: '0 0 4px rgba(196, 196, 196, 0.5)', // Optional shadow
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontFamily: 'PP Gosha Sans, Arial, sans-serif',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '1.5em',
                        paddingBottom: '8px', // Placeholder alignment
                        color: '#121212', // Text color

                        '&::placeholder': {
                            color: '#121212', // Placeholder color
                            transition: 'color 0.3s ease', // Smooth transition for placeholder
                            fontSize: '16px', // Default font size

                            // Media queries
                            '@media (min-width: 768px) and (max-width: 1023px)': {
                                fontSize: '14px', // Font size per tablet
                            },
                            '@media (max-width: 767px)': {
                                fontSize: '12px', // Font size per mobile
                            },
                        },

                        '&:hover::placeholder': {
                            color: '#121212', // Placeholder color on hover
                        },
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px', // Space between icon and text
                },
                icon: {
                    width: '24px',
                    height: '24px', // Icon dimensions
                },
            },
        },
    },
};

// Light Theme
const lightTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'light',
        primary: {
            main: '#2ABF67', // Action 1
            button: '#FBFBFB', // Neutral
        },
        secondary: {
            main: '#FFC54D', // Action 2
            button: '#121212', // Neutral
        },
        background: {
            default: '#FFFFFF', // Neutral White
            paper: '#FBFBFB', // Neutral
        },
        text: {
            primary: '#121212', // Black
            secondary: '#6B6B6B', // Dark Grey
            white: '#f6f6f6',
            disabled: '#C4C4C4', // Middle Grey
        },
        action: {
            hover: '#F9F5CC', // Secondary
        },
        divider: '#C4C4C4', // Middle Grey
    },
});

// Dark Theme
const darkTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'dark',
        primary: {
            main: '#2ABF67', // Action 1
        },
        secondary: {
            main: '#FFC54D', // Action 2
        },
        background: {
            default: '#121212', // Black
            paper: '#1E1E1E', // Dark Grey
        },
        text: {
            primary: '#FFFFFF', // White
            secondary: '#AAAAAA', // Light Grey
            disabled: '#555555', // Disabled Grey
        },
        action: {
            hover: '#333333', // Darker Grey for Hover
        },
        divider: '#6A6A6A', // Dark Grey Divider
    },
});

export { lightTheme as default, darkTheme }; // Esporta il tema light come predefinito
