import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axiosInstance from "../../api/axiosConfig";
import CardProduct from "./CardProduct"; // ✅ Componente Card

// Styled Components
const RelatedsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative; /* Necessario per posizionare le frecce */
`;

const SectionTitle = styled.h2`
  font-family: "PP Gosha Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Grid = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto; /* Abilita lo scorrimento orizzontale */
  scroll-behavior: smooth; /* Scroll fluido per il browser */
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* Nasconde la scrollbar in IE e Edge */
  scrollbar-width: none; /* Nasconde la scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none; /* Nasconde la scrollbar in Chrome e Safari */
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 2;

  &:hover {
    background-color: #f0f0f0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: -20px;
`;

const RightArrow = styled(ArrowButton)`
  right: -20px;
`;

const CardWrapper = styled.div`
  scroll-snap-align: center; /* Allinea ogni card al centro durante lo scorrimento */
  flex-shrink: 0;
`;

const AdRelateds = ({ annuncioId }) => {
  const [annunciCorrelati, setAnnunciCorrelati] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);

  useEffect(() => {
    if (!annuncioId) return;

    const fetchAnnunciCorrelati = async () => {
      try {
        const response = await axiosInstance.get(`/annuncio/correlati/${annuncioId}/`);
        console.log("✅ Annunci correlati:", response.data);
        setAnnunciCorrelati(response.data);
      } catch (error) {
        console.error("❌ Errore nel recupero degli annunci correlati:", error);
        setError("Impossibile caricare gli annunci correlati.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnnunciCorrelati();
  }, [annuncioId]);

const scrollGrid = (direction) => {
    const scrollAmount = 300; // Quantità di scorrimento in px
    if (gridRef.current) {
        gridRef.current.scrollTo({
            left: gridRef.current.scrollLeft + (direction === "left" ? -scrollAmount : scrollAmount),
            behavior: "smooth", // Aggiunge l'effetto ease
        });
    }
};

  if (loading) return <p>Caricamento annunci correlati...</p>;
  if (error) return <p>{error}</p>;

  return (
    <RelatedsWrapper>
      <div>
        <SectionTitle>Annunci Suggeriti</SectionTitle>

        {/* Griglia con overflow e frecce */}
        <Grid ref={gridRef}>
          {annunciCorrelati.map((annuncio) => (
            <CardWrapper key={annuncio.id}>
              <CardProduct
                id={annuncio.id}
                images={annuncio.images_urls}
                title={annuncio.titolo}
                seller={annuncio.user_username}
                state={annuncio.condizioni}
                price={annuncio.prezzo}
                sponsored={annuncio.is_featured}
                onClick={() => (window.location.href = `/annuncio/${annuncio.id}`)}
              />
            </CardWrapper>
          ))}
        </Grid>

        {/* Frecce */}
        <LeftArrow onClick={() => scrollGrid("left")}>{"<"}</LeftArrow>
        <RightArrow onClick={() => scrollGrid("right")}>{">"}</RightArrow>
      </div>
    </RelatedsWrapper>
  );
};

export default AdRelateds;
