import React, { useState } from 'react';
import RoundButton from '../RoundButton';
import { Menu, MenuItem, TextField, List, ListItem, Typography, Avatar, IconButton, Badge } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import styled from 'styled-components';
import { useAnnuncio } from '../../context/AnnuncioContext';

const SidebarContainer = styled.div`
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;
`;

const StyledListItem = styled(ListItem)`
    cursor: pointer;
    background-color: ${(props) => (props.selected ? '#f0f0f0' : 'transparent')};
    border-radius: 8px;
    margin-bottom: 4px;
    &:hover {
        background-color: #f5f5f5;
    }
`;

const DateText = styled(Typography)`
    font-size: 12px;
    color: #999;
    text-align: right;
`;

const Sidebar = ({ conversazioni, selectedMessage, setSelectedMessage, setAnnuncio }) => {
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState('all');

    const { fetchAnnuncioCorrente } = useAnnuncio();

    const handleSearchClick = () => setSearchOpen(!searchOpen);
    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const handleFilterClick = (event) => setAnchorEl(event.currentTarget);
    const handleFilterClose = () => setAnchorEl(null);

    const handleFilterChange = (filter) => {
        setFilter(filter);
        setAnchorEl(null);
    };

    // 🔄 Ordina le conversazioni per data in ordine decrescente
    const sortedConversazioni = [...conversazioni].sort((a, b) => 
        new Date(b.data_ultimo_messaggio) - new Date(a.data_ultimo_messaggio)
    );

    // 🔄 Filtra le conversazioni in base alla ricerca e al filtro selezionato
    const filteredConversazioni = sortedConversazioni.filter((msg) => {
        const matchesSearch = msg.venditore?.nome?.toLowerCase().includes(searchQuery.toLowerCase()) || false;
        const matchesFilter =
            filter === 'all' ||
            (filter === 'unread' && msg.nonLetti > 0);
        return matchesSearch && matchesFilter;
    });

    // 🛠️ Gestisce il click su una conversazione
    const handleConversationClick = async (msg) => {
        setSelectedMessage(msg);
        if (msg.annuncio) {
            const annuncio = await fetchAnnuncioCorrente(msg.annuncio);
            setAnnuncio(annuncio);
        }
    };

    return (
        <SidebarContainer>
            <Typography variant="h6" sx={{ mb: 2 }}>Messaggi</Typography>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <RoundButton onClick={handleSearchClick}>
                    <SearchIcon />
                </RoundButton>
                {searchOpen && (
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Cerca..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ ml: 2, flex: 1 }}
                    />
                )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <IconButton onClick={handleFilterClick}>
                    <FilterListIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleFilterClose}
                >
                    <MenuItem onClick={() => handleFilterChange('all')}>Tutti</MenuItem>
                    <MenuItem onClick={() => handleFilterChange('unread')}>Non letti</MenuItem>
                </Menu>
            </div>

            <List>
                {filteredConversazioni.map((msg) => {
                    const messageDate = new Date(msg.data_ultimo_messaggio).toLocaleDateString('it-IT', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    });
                    return (
                        <StyledListItem
                            key={msg.id}
                            selected={selectedMessage?.id === msg.id}
                            onClick={() => handleConversationClick(msg)}
                        >
                            <Badge
                                color="primary"
                                badgeContent={msg.nonLetti}
                                invisible={msg.nonLetti === 0}
                                sx={{ mr: 2 }}
                            >
                                <Avatar src={msg.venditore?.avatar || "https://via.placeholder.com/50"} />
                            </Badge>
                            <div style={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1">
                                    {msg.venditore?.nome || "Venditore sconosciuto"}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {msg.ultimo_messaggio || "Nessun messaggio"}
                                </Typography>
                            </div>
                            <DateText>{messageDate}</DateText>  {/* 🔄 Mostra la data in formato GG/MM/AAAA */}
                        </StyledListItem>
                    );
                })}
                {filteredConversazioni.length === 0 && (
                    <Typography variant="body2" color="textSecondary" align="center">
                        Nessuna conversazione trovata
                    </Typography>
                )}
            </List>
        </SidebarContainer>
    );
};

export default Sidebar;
